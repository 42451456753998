import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast'

function Index() {
  const { id } = useParams();
  console.log("IDDDDDDDDD", id)
  const navigate = useNavigate()
  const [pending, setPending] = useState(false);

  const [staffs, setStaffs] = useState([]);

  const handleActive = async (specialistId) => {
    console.log("sssssssssssss", specialistId)
    const data = {
      specialistId: specialistId,
      status: false
    }
    console.log("ActiveDta", data)
    const res = await HttpClient.requestData(`change-salon-specialist-status/${id}`, "PUT", data)
    console.log("ActiveStats", res)
    if (res && res.status) {
      toast.success("Activated Successfully");
      fetchStaff();
    } else {
      toast.error(res?.message)
    }
  }
  const handleDeactive = async (specialistId) => {
    const data = {
      specialistId: specialistId,
      status: true
    }
    const res = await HttpClient.requestData(`change-salon-specialist-status/${id}`, "PUT", data)
    console.log("DeacrivateStats", res)
    if (res && res.status) {
      toast.success("Deactivated Successfully");
      fetchStaff();
    } else {
      toast.error(res?.message)
    }
  }

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    let result = await HttpClient.requestData(`viewAllSpecialist/${id}`, "GET");
    console.log("resSaloonStuff", result);
    if (result && result.status) {
      let arr = result.data.map((item, i) => {
        return {
          sl: i + 1,
          ...item,
          image: (
            <img src={item.image} style={{ height: "100px", width: "100px" }} />
          ),
          service_provide: (
            <>
              {item.service.map((it) => {
                return <li>{it.name}</li>;
              })}
            </>
          ),
          status: item.activeMember ? (
            <button onClick={() => handleActive(item?._id)} className="btn btn-success ">Active</button>
          ) : (
            <button onClick={() => { handleDeactive(item?._id) }} className="btn btn-danger">Deactive</button>
          ),
          attend_user: <button className="btn btn-primary" onClick={() => navigate(`/attend-customer/${item._id}`)}>View </button>,
        };
      });
      setStaffs(arr);
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Image",
      selector: (row) => row.image,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
    },
    {
      name: "D.O.B",
      selector: (row) => row.age,
    },
    {
      name: "Mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "Salary",
      selector: (row) => row.salary,
    },
    {
      name: "Service Provide",
      selector: (row) => row.service_provide,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "Attend Customer",
      selector: (row) => row.attend_user,
    },
  ];

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Saloon Staffs</h5>
      </div>

      <DataTable
        progressPending={pending}
        columns={columns}
        data={staffs}
        pagination
      />
    </>
  );
}

export default Index;
