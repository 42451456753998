import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { AiFillStar } from "react-icons/ai";
import { toast } from "react-toastify";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./TableCol";
var constState = null;
const ViewRating = () => {
  const [rating, setRating] = useState([]);
  const [pending, setPending] = useState(true);
  useEffect(() => {
    getCoustomerRating();
  }, []);
  const getCoustomerRating = async () => {
    let result = await HttpClient.requestData(`fetchCustomerRating`, "GET", {});

    if (result && result.status) {
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          Rating: (
            <>
            
                {item.rating.avgRating}
           
            </>
          ),
          // star:<AiFillStar/>
        };
      });
      setPending(false);
      constState = arr;
      setRating(arr);
    } else {
      setPending(false);
      toast.error(result.message || result.error);
    }
  };

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Customer Ratings</h5>
      </div>
      <FilterComponent
        state={rating}
        setState={setRating}
        constState={constState}
      />
      <DataTable
        progressPending={pending}
        columns={columns}
        data={rating}
        pagination
      />
    </>
  );
};

export default ViewRating;
