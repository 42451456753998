import React from "react";

const Name = ({ label, state, setState, placeholder, icons, req }) => {
  return (
    <div className="form-group">
      <label>{label} {req && <span style={{ color: "red" }}>*</span>}</label>
      <div className="input-group">
        <span className="input-group-text">
          {icons ? <i className="icon-user" /> : null}
        </span>
        <input
          className="form-control p_input"
          name="name"
          value={state.name}
          onChange={(e) => {
            setState({ ...state, name: e.target.value });
          }}
          type="text"
          required
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Name;
