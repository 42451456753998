import React, { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { reactLocalStorage } from "reactjs-localstorage";
import Header from "../Common/Layout/header";
import SideBar from "../Common/Layout/sideBar";
import Footer from "../Common/Layout/footer";
import { ToastContainer } from "react-toastify";
import { Toaster } from "react-hot-toast";
var data = reactLocalStorage?.get("loginStatus");
if (data) {
  var isAuth = JSON.parse(data)?.status;
}
// console.log("is", isAuth);
function PrivateRoutes() {
  return reactLocalStorage.get("loginStatus") ? (
    <>
      <div>
        {" "}
        <ToastContainer />
        <Toaster
          toastOptions={{
            success: {
              style: {
                background: "green",
              },
            },
            error: {
              style: {
                background: "red",
              },
            },
          }}
        />
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          <div className="page-main-header">
            <Header />
          </div>
          <div className="page-body-wrapper sidebar-icon">
            <div className="page-body">
              <div className="card">
                <Outlet />
              </div>
            </div>
          </div>

          <SideBar />
          <Footer />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" />
    // <Outlet />
  );
}

export default PrivateRoutes;
