export const appCol = [
    {
        name: 'Sl No',
        selector: row => row.sl,
    },{
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    }, {
        name: 'Expert',
        selector: row => row.expert,
    }, 
    {
        name: 'Number',
        selector: row => row.mobile,
    },
    {
        name: 'Action',
        selector: row => row.action,
    },
    {
        name: 'Track',
        selector: row => row.track,
    },
];
