import React from 'react'

const Price = ({ label, state, setState,placeholder }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="icon-price" />
        </span>
        <input
          className="form-control p_input"
          name="text"
          value={state.price}
          onChange={(e) => {
            setState({ ...state, price: e.target.value });
          }}
          type="text"
          required
          placeholder={placeholder}
        />
      </div>
    </div>  )
}

export default Price