export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Price",
    selector: (row) =>"₹"+ row.price,
  },
  {
    name: "Duration / Days",
    selector: (row) => row.noOfDays,
  },
  {
    name: "No. of Persons",
    selector: (row) => row.noOfPerson,
  },
  {
    name: "Additional Days",
    selector: (row) => row.additionalDays,
  },
  {
    name: "Priority",
    selector: (row) => row.priority
    ,
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
  {
    name: "Update",
    selector: (row) => row.action,
  },
  {
    name: "Delete",
    selector: (row) => row.delete,
  },
];
