import React, { useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import HttpClient from "../../../utils/HttpClient";
import Price from "../../Subscription/AddSubs/Component/Price";
// import Price from "../../../Component/Forms/Price";
// import HttpClient from "../../../HttpClient";
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const PaymentModal = ({ data, callback }) => {
  // console.log("data", data);
  const body = {
    month: "",
    price: "",
  };

  const modalRef = useRef();
  const [paymentData, setPaymentData] = useState(body);
  const submit = async () => {
    let Data = {
      ...paymentData,
      salonId: data?.id,
    };
    // console.log("paymentData", Data);

    let res = await HttpClient.requestData("AdminPaySalon", "POST", Data);
    // console.log(res);
    if (res && res.status) {
      setPaymentData(body);
      if (res.message === "already payment done for this month") {
        toast.warn(res.message);
        setTimeout(() => {
          modalRef.current.click();
        }, 1000);
      } else {
        callback();
        toast.success(res.message);
        setTimeout(() => {
          modalRef.current.click();
        }, 1000);
      }
    }
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <ToastContainer />
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Pay {data?.name}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form action="" onSubmit={() => submit()} className="px-5">
              <div className="form-group">
                <label for="sel1">Month</label>
                <select
                  onChange={(e) => {
                    setPaymentData({
                      ...paymentData,
                      month: e.target.value,
                    });
                  }}
                  className="form-control"
                  id="sel1"
                >
                  <option value="">select</option>
                  {months.map((india, ind) => {
                    return (
                      <option key={ind} value={india}>
                        {india}
                      </option>
                    );
                  })}
                </select>
              </div>

              <Price
                value={paymentData.price}
                state={paymentData}
                setState={setPaymentData}
                placeholder="Enter Ammount"
              />
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button onClick={submit} type="button" className="btn btn-primary">
              submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
