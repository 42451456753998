import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Discount from "../../../Common/Layout/FormInput/Discount";
import ImageUpload from "../../../Common/Layout/FormInput/ImageUpload";
import Name from "../../../Common/Layout/FormInput/Name";
import HttpClient from "../../../utils/HttpClient";
import FromDate from "./FormComponent/FromDate";
import ToDate from "./FormComponent/ToDate";
const body = {
  name: "",
  discount: "",
  from: "",
  to: "",
  image: "",
};
const Index = () => {
  const location = useLocation().state;
  const [formData, setFormData] = useState(location || body);
  const navigate =useNavigate()
  const submit = async (e) => {
    e.preventDefault();
    console.log("formData",formData);
    if (
      formData.name !== "" &&
      formData.discount != "" &&
      formData.from !== "" &&
      formData.to !== "" &&
      formData.image !==""
    ) {
      if (location) {
        // While updating
        let res = await HttpClient.requestData(
          `updateCoupon/${location._id}`,
          "PUT",
          formData
        );
        if (res && res.status) {
          toast.success("Coupon Updated Successfully");
          setTimeout(() => {
            navigate('/view-coupons')
          }, 1000);
        } else {
          toast.error(res.message);
        }
      } else {
        // While Creating
        let res = await HttpClient.requestData("addCoupon", "POST", formData);
        if (res && res.status) {
          toast.success(res.message);
          navigate("/view-coupons")
        } else {
          toast.error("Coupon Added Successfully");
        }
      }
    }else{
      toast.warn("All Field Are Required !!!")
    }
  };
  // console.log("formData", formData);
  return (
    <>
      <div className="card-header pb-0">
        <h5>Add Coupons</h5>
      </div>
      <form className="theme-form login-form" onSubmit={submit}>
        <h4>Add Coupons</h4>
        <h6>Add coupon information from here</h6>
        <Name
        icons={false}
          label="Coupon Name"
          state={formData}
          setState={setFormData}
          placeholder="enter coupon name"
        />
        <Discount
          label="coupon discount"
          state={formData}
          setState={setFormData}
          placeholder="enter discount"
        />
        <FromDate
          label="from"
          state={formData}
          setState={setFormData}
          placeholder="coupon validity start date"
          editEnable={location}
        />
        <ToDate
          label="To"
          state={formData}
          setState={setFormData}
          placeholder="coupon validity end date"
          editEnable={location}
        />
        <ImageUpload
          label="Coupon Image"
          value={formData.image}
          state={formData}
          setState={setFormData}
          ApiEndPoint="couponImageUpload"
        />
        <div className="form-group">
          <button className="btn btn-primary btn-block" type="submit">
         {location?"Update Coupon":"Add Coupon"}  
          </button>
        </div>
      </form>
    </>
  );
};

export default Index;
