import { Button, Switch } from "@mui/material";

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import FilterComponent from "../../Common/SearchData/Index";
import Httpclient from "../../utils/HttpClient";
import { appCol } from "./AppCol";
import { pendingCol } from "./PendinCol";
import HttpClient from "../../utils/HttpClient";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";

const FreelancerTrack = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pendConst, setpendConst] = useState([]);
  const [appConst, setappConst] = useState([]);
  const [pendingLancer, setPendingLancer] = useState([]);
  const [approved, setApproved] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let res = await Httpclient.requestData(
      `viewFreelancerDetails/${id}`,
      "GET"
    );
    console.log("freeRes", res);
    if (res && res.status) {
      let arr = [];
      arr = res.data.map((item, ind) => {
        return {
          ...item,

          sl: ind + 1,
          //   action: (
          //     <Button
          //       size="small"
          //       onClick={() => {
          //         // confirm(item.free[0]);
          //       }}
          //       variant="contained"
          //       color="success"
          //     >
          //       approve
          //     </Button>
          //   ),
        };
      });
      setpendConst(arr);
      //   setPendingLancer(arr);
    }
  };

  const appCol = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Salon Name",
      selector: (row) => row.salonName,
    },
    {
      name: "Salon Address",
      selector: (row) => row.salonAddress,
    },
    {
      name: "User Attend",
      selector: (row) => row.userName,
    },
    {
      name: "Service Provide",
      selector: (row) => row.serviceName,
    },
    {
      name: "User Attend",
      selector: (row) => row.userName,
    },
    {
      name: "Service Date ",
      selector: (row) => moment(row.bookingDate).format("ll"),
    },
    {
      name: "Time",
      selector: (row) => row.bookingTime,
    },
    {
      name: "Fess",
      selector: (row) => row.totalAmount,
    },
    // {
    //     name: 'Track',
    //     selector: row => row.track,
    // },
  ];

  return (
    <>
      <div className="card-header pb-0">
        <h5> Freelancer Track</h5>
      </div>
      {/* <FilterComponent
        state={pendingLancer}
        setState={setPendingLancer}
        constState={pendConst}
      />
      <DataTable
        columns={pendingCol}
        data={pendingLancer}
        pagination
        //   onRowClicked={(row, e) => handleRowClicked(row, e)}
      />{" "}
      <div className="card-header pb-0">
        <h5>Approved Freelancer</h5>
      </div>
      <FilterComponent
        state={approved}
        setState={setApproved}
        constState={appConst}
      /> */}
      <DataTable
        columns={appCol}
        data={pendConst}
        pagination
        //   onRowClicked={(row, e) => handleRowClicked(row, e)}
      />
    </>
  );
};

export default FreelancerTrack;
