import React from "react";

const MonthDuration = ({ state, setState }) => {
  let month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // console.log("state.duration", state.duration);
  return (
    <div className="d-flex align-items-center justify-content-between mb-2">
      <div className="form-group">
        <label for="sel1">Duration / Days</label>
        <input
          className="form-control p_input"
          name="name"
          value={state.noOfDays}
          onChange={(e) => {
            setState({ ...state, noOfDays: e.target.value });
          }}
          type="number"
          required
          placeholder="Days"
        />
        {/* <select
          onChange={(e) => {
            setState({
              ...state,
              duration: e.target.value,
            });
          }}
          className="form-control"
          id="sel1"
        >
          <option value={state.duration}>
            {state.duration === "" ? "select duration" : state.duration}
          </option>
          {month.map((india, ind) => {
            return (
              <option key={ind} value={india}>
                {india}
              </option>
            );
          })}
        </select> */}
      </div>
    </div>
  );
};

export default MonthDuration;
