import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HttpClient from "../../../utils/HttpClient";
import DataTable from "react-data-table-component";
const immmm =
  "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/1200px-Image_created_with_a_mobile_phone.png";
const body = {
  sellerId: "",
  noOfUser: null,
  noOfSalon: null,
};
const AddTarget = () => {
  const [sellerData, setSellerData] = useState(useLocation().state);
  const [formData, setFormData] = useState(body);
  const [targetData, setTargetData] = useState([]);
  const userId = useLocation().state._id;
  const navigate = useNavigate()

  const [model, setModel] = useState(false);
  const [imageSrc, setImagesrc] = useState("");

  const [model1, setModel1] = useState(false);
  const [imageSrc1, setImagesrc1] = useState("");

  const [saloonList, setSaloonList] = useState([]);
  const [usersList, setUsersList] = useState([])
  const defaultImage = "https://t3.ftcdn.net/jpg/04/62/93/66/360_F_462936689_BpEEcxfgMuYPfTaIAOC1tCDurmsno7Sp.jpg"
  console.log("UsersListsss", usersList)

  console.log("Id", userId);
  // console.log("sellerData", sellerData);
  const handleCommison = (item) => {
    navigate("/CommisionFromUser", { state: item })
  }

  useEffect(() => {
    getTarget();
    fetchSalonListAgainstSeller(userId);
  }, []);

  const Model = (src) => {
    setModel(true);
    setImagesrc(src);
  };

  const Close = () => {
    setModel(false);
    setImagesrc("");
  };

  const Model1 = (src) => {
    setModel1(true);
    setImagesrc1(src);
  };

  const Close1 = () => {
    setModel1(false);
    setImagesrc1("");
  };

  const fetchSalonListAgainstSeller = async (id) => {
    let endpoint = `seller/salonListAgainstSeller/${id}`;

    let result = await HttpClient.requestData(endpoint, "GET");
    if (result && result?.status) {
      let arr = result?.data?.map((item, index) => {
        return {
          sl: index + 1,
          name: item?.name,
          image: (
            <>
              {item?.image ? (
                <img
                  onClick={() => Model(item?.image)}
                  style={{
                    width: "45%",
                    borderRadius: "12px",
                    margin: "9px 35px",
                  }}
                  src={item?.image}
                />
              ) : (
                <img
                  style={{
                    width: "45%",
                    borderRadius: "12px",
                    margin: "9px 35px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                />
              )}
            </>
          ),
          email: item?.email,
          state: item?.state,
          pinCode: item?.pinCode,
          tradeLicenseImage: (
            <>
              {item?.tradeLicenseImage ? (
                <img
                  onClick={() => Model1(item?.tradeLicenseImage)}
                  style={{
                    width: "45%",
                    borderRadius: "12px",
                    margin: "9px 35px",
                  }}
                  src={item?.tradeLicenseImage}
                />
              ) : (
                <img
                  style={{
                    width: "45%",
                    borderRadius: "12px",
                    margin: "9px 35px",
                  }}
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                />

              )}
            </>
          ),
          openingTime: item?.openingTime,
          closingTime: item?.closingTime,
          totalSeat: item?.totalSeat,
          address: item?.address,
          creatingDate: item?.dob,
          isDeleted: JSON.stringify(item?.isDeleted),
          mobile: item?.mobile,
          payment: JSON.stringify(item?.payment),
          approveStatus: JSON.stringify(item?.approveStatus),
          enableStatus: JSON.stringify(item?.enableStatus),
          avgRating: item?.avgRating,
          totalStaff: item?.totalStaff,
          status: JSON.stringify(item?.status),
        };
      });
      setSaloonList(arr);
    }
  };

  // console.log("sellerData", sellerData);
  const submit = async (e) => {
    e.preventDefault();
    // console.log(userId, formData.noOfSalon);
    if (
      userId !== "" &&
      formData.noOfSalon !== "" &&
      formData.noOfUser !== ""
    ) {
      // console.log("first");
      if (formData.noOfSalon < 1 && formData.noOfUser < 1) {
        return false;
      }
      let data = {
        sellerId: userId,
        noOfUser: formData.noOfUser,
        noOfSalon: formData.noOfSalon,
        EntryDate: new Date().toString(),
      };
      // console.log("data", data);

      let result = await HttpClient.requestData(`addTarget`, "POST", data);
      // console.log("result", result);
      if (result && result.status) {
        getTarget();
        toast.success(`updated sucessfully !`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(`error !`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Saloon Name",
      selector: (row) => row.name,
    },
    {
      name: <div style={{ marginLeft: "10px" }}>Saloon Image</div>,
      selector: (row) => row.image,
    },
    {
      name: "Saloon Email",
      selector: (row) => row.email,
    },
    // {
    //   name: "Saloon state",
    //   selector: (row) => row.state,
    // },
    // {
    //   name: "Saloon pinCode",
    //   selector: (row) => row.pinCode,
    // },
    {
      name: "TradeLicenseImage",
      selector: (row) => row.tradeLicenseImage,
    },
    {
      name: "OpeningTime",
      selector: (row) => row.openingTime,
    },
    {
      name: "ClosingTime",
      selector: (row) => row.closingTime,
    },
    {
      name: "TotalSeat",
      selector: (row) => row.totalSeat,
    },
    {
      name: "Address",
      selector: (row) => row.address,
    },
    {
      name: "Creating Date",
      selector: (row) => row.creatingDate,
    },
    {
      name: "IsDeleted",
      selector: (row) => row.isDeleted,
    },

    {
      name: "Mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "Payment",
      selector: (row) => row.payment,
    },
    {
      name: "ApproveStatus",
      selector: (row) => row.approveStatus,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "EnableStatus",
      selector: (row) => row.enableStatus,
    },
    {
      name: "AvgRating",
      selector: (row) => row.avgRating,
    },
    {
      name: "TotalStaff",
      selector: (row) => row.totalStaff,
    },

    // {
    //   name: "Avtar",
    //   selector: (row) => (
    //     <img style={{ height: 38, width: 38 }} src={row.image} />
    //   ),
    // },
    // {
    //   name: "Name",
    //   selector: (row) => row.name,
    // },
    // {
    //   name: "Email",
    //   selector: (row) => row.email,
    // },
    // {
    //   name: "Date",
    //   selector: (row) => row.datee,
    // },
    // {
    //   name: "Number",
    //   selector: (row) => row.mobile,
    // },
    // {
    //   name: "Password",
    //   selector: (row) => row.password,
    // },
  ];


  {/**Users List */ }
  const sellerColumns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Users Name",
      selector: (row) => row.name,
    },
    {
      name: <div style={{ marginLeft: "10px" }}>Users Image</div>,
      selector: (row) => row.image,
    },
    {
      name: "Users Email",
      selector: (row) => row.email,
    },
    // {
    //   name: "Saloon state",
    //   selector: (row) => row.state,
    // },
    // {
    //   name: "Saloon pinCode",
    //   selector: (row) => row.pinCode,
    // },
    {
      name: "Users Mobile No",
      selector: (row) => row.mobile,
    },
    {
      name: "Users State",
      selector: (row) => row.state,
    },
    {
      name: "Users Pincode",
      selector: (row) => row.pinCode,
    },
    {
      name: "Users Address",
      selector: (row) => row.address,
    },
    {
      name: "Users Subscription Status",
      selector: (row) => row.subscriptionStatus,
    },

    {
      name: "Mobile",
      selector: (row) => row.mobile,
    },
    {
      name: "commision",
      selector: (row) => row.commisionBtn,
    },


    // {
    //   name: "Avtar",
    //   selector: (row) => (
    //     <img style={{ height: 38, width: 38 }} src={row.image} />
    //   ),
    // },
    // {
    //   name: "Name",
    //   selector: (row) => row.name,
    // },
    // {
    //   name: "Email",
    //   selector: (row) => row.email,
    // },
    // {
    //   name: "Date",
    //   selector: (row) => row.datee,
    // },
    // {
    //   name: "Number",
    //   selector: (row) => row.mobile,
    // },
    // {
    //   name: "Password",
    //   selector: (row) => row.password,
    // },
  ]

  // model
  const fetchUsersList = async () => {
    console.log("userIdsssssss", userId)
    const res = await HttpClient.requestData(`seller/userList-for-particular-seller/${userId}`, "GET")
    console.log("UsersListRes", res)
    if (res && res?.status) {
      let arr = res?.data?.map((item, index) => {
        return {
          sl: index + 1,
          name: item?.name,
          image: <img style={{
            width: "45%",
            borderRadius: "12px",
            margin: "9px 35px",
          }} src={item?.image} />,
          email: item?.email,
          mobile: item?.mobile,
          state: item?.state,
          pinCode: item?.pinCode,
          address: item?.address,
          subscriptionStatus: item?.subscriptionStatus.toString(),
          commisionBtn: <button onClick={() => handleCommison(item)} className="btn btn-success">Commision</button>
        }

      })
      setUsersList(arr)
    }
  }
  useEffect(() => {
    fetchUsersList()
  }, [])

  const getTarget = async () => {
    let result = await HttpClient.requestData(`viewAllTarget`, "GET", {});

    if (result && result.status) {
      // console.log(result.noOfSalon, `result111`);

      let data = result.data.find((it) => it.sellerId === sellerData._id);
      setSellerData({
        ...sellerData,
        noOfUser: data.noOfUser,
        noOfSalon: data.noOfSalon,
      });
      setFormData({
        ...formData,
        noOfSalon: data.noOfSalon,
        noOfUser: data.noOfUser,
      });
      // setTargetData(result.data); // set data
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const addtarget = () => {
    document.getElementById("sallonFocus").focus();
  };
  console.log("sellerData", sellerData);
  return (
    <>
      <div className="mainBox">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-xl-7 col-12">
              <div
                className=""
                style={{
                  width: "100%",
                  maxWidth: "330px",
                  height: "230px",
                  borderRadius: "10px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={sellerData?.image}
                  alt=""
                  className="img-fluid"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
              <div className="card-body p-0">
                <h5 className="card-title mt-3">{sellerData?.name}</h5>
                <p className="card-text">
                  Phone : {sellerData?.mobile}
                  <br />
                  Email :{sellerData?.email}
                  <br />
                  <span style={{ fontSize: 15, fontWeight: 600 }}>
                    password : {sellerData?.password}
                  </span>{" "}
                  {sellerData?.passwordCrypto}
                </p>
                {sellerData?.noOfSalon === undefined ||
                  sellerData?.noOfSalon === null ||
                  sellerData?.noOfSalon === "" ? (
                  <h6 style={{ fontWeight: "700" }}>
                    No target yet
                    <button
                      className="btn btn-sm btn-success m-1"
                      onClick={addtarget}
                    >
                      add target
                    </button>
                  </h6>
                ) : (
                  <>
                    <h6 style={{ fontWeight: "600" }}>
                      Daily saloon target: {sellerData?.noOfSalon}
                    </h6>
                    <h6 style={{ fontWeight: "600" }}>
                      Daily users target: {sellerData?.noOfUser}
                    </h6>
                  </>
                )

                }
                <p className="card-text" />
              </div>
            </div>
            <div className="col-lg-5 col-xl-5 col-12">
              <div className="theme-form login-form">
                <div className="login_Overflow mt-5">
                  <div className="login_Inner d-flex align-items-center justify-content-center">
                    <div className="login_Start w-100">
                      <form action="" className="">
                        <h3 className="mb-6 mt-4 text-center login_TExt">
                          Add daily target
                        </h3>
                        <div className="form-group mb-4">
                          <label htmlFor="name" className="mb-2">
                            Add daily saloon target
                          </label>
                          <input
                            id="sallonFocus"
                            type="number"
                            className="form-control"
                            required=""
                            placeholder="Enter saloon target"
                            defaultValue=""
                            value={formData.noOfSalon}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                noOfSalon: e.target.value,
                              });
                            }}
                          />
                          <div className="valid-feedback">Valid.</div>
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        <div className="form-group mb-4">
                          <label htmlFor="name" className="mb-2">
                            Add daily Users target
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            required=""
                            placeholder="Enter user target"
                            defaultValue=""
                            value={formData.noOfUser}
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                noOfUser: e.target.value,
                              });
                            }}

                          />
                          <div className="valid-feedback">Valid.</div>
                          <div className="invalid-feedback">
                            Please fill out this field.
                          </div>
                        </div>
                        <button
                          onClick={submit}
                          className="btn btn-success my-3 mr-3"
                        >
                          Update
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr></hr>
      <div className="mainBox">
        <div className="container">
          <h5 style={{ textAlign: "center", marginTop: "41px" }}>
            Sallon List
          </h5>
          <DataTable columns={columns} data={saloonList} pagination />
          {model ? (
            <>
              <div
                tabIndex={-1}
                role="menu"
                aria-hidden="false"
                className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                data-popper-placement="bottom-end"
                style={{
                  position: "absolute",
                  inset: "14px 38px auto auto",
                  transform: "translate(0px, 44px)",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{
                    height: "400px",

                  }}
                  src={imageSrc}
                />
                {/* <button onClick={() => Close()}>x</button> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <svg
                    onClick={() => Close()}
                    style={{ color: "red", cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </>
          ) : null}

          {model1 ? (
            <>
              <div
                tabIndex={-1}
                role="menu"
                aria-hidden="false"
                className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                data-popper-placement="bottom-end"
                style={{
                  position: "absolute",
                  inset: "14px 38px auto auto",
                  transform: "translate(500px, 44px)",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{
                    height: "400px",

                  }}
                  src={imageSrc1}
                />
                {/* <button onClick={() => Close()}>x</button> */}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <svg
                    onClick={() => Close1()}
                    style={{ color: "red", cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <h5 style={{ textAlign: "center", marginTop: "41px" }}>
          Users List
        </h5>
        <DataTable columns={sellerColumns} data={usersList} pagination />
      </div>
    </>
  );
};

export default AddTarget;

// width: "89%" , borderRadius:'12px' , margin:'9px 35px'
