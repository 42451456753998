import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import { columns } from "./TableCol";

const Index = () => {
  const [data, setData] = useState();
  const location=useLocation().state.service
  console.log('location', location)
  useEffect(() => {
    let arr = location?.map((item, ind) => {
      return {
        ...item,
        sl: ind + 1,
      };
    });
    setData(arr);
  }, []);

  return (
    <>
      <div className="card-header pb-0">
        <h5>View {} Services </h5>
      </div>
      {/* <FilterComponent
      state={sellsArr}
      setState={setSellsArr}
      constState={constState}
    /> */}
      <DataTable
        // title="Contact List"
        columns={columns}
        data={data}
        // progressPending={pending}
        pagination
      />
    </>
  );
};

export default Index;
