import { Toast } from "bootstrap";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./TableCol";
var constState = null;
const Index = () => {
  useEffect(() => {
    getSpecialistRAting();
  }, []);

  const [rating, setRating] = useState([]);
  const [pending, setPending] = useState(true);
  const getSpecialistRAting = async () => {
    let result = await HttpClient.requestData(
      `fetchSpecialistRating`,
      "GET",
      {}
    );
console.log("result",result);
    if (result && result.status) {
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          name: item.specialist.name,
          Rating: item.rating,
        };
      });
      setPending(false)
      constState = arr;
      setRating(arr);
    } else {
      setPending(false);
      toast.error(result.message || result.error);
    }
  };

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Specialist Ratings</h5>
      </div>
      <FilterComponent
        state={rating}
        setState={setRating}
        constState={constState}
      />
      <DataTable
        progressPending={pending}
        columns={columns}
        data={rating}
        pagination
      />
    </>
  );
};

export default Index;
