import React from "react";
const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];
const ServiceModal = ({ data = [], state, setState }) => {
  console.log("data", data);
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Services
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table table-striped" style={{overflow:"scroll"}}>
              <thead>
                <tr>
                  <th scope="col">Sl no</th>
                  <th scope="col">Service Name</th>
                  <th scope="col">Gender</th>

                  <th scope="col"> Start Price</th>
                  <th scope="col"> End Price</th>
                  <th scope="col"> No. of Slot</th>


                </tr>
              </thead>
              <tbody>
                <tr>
                  <>
                    <th scope="row">1</th>
                    <td>{data?.serviceName}</td>
                    <td>{data?.gender}</td>

                    <td> ₹ {data?.startPrice}</td>
                    <td> ₹ {data?.endPrice}</td>
                    <td>{data?.noOfSlot}</td>
                  </>

                  {/* {data?.map((item, ind) => {
                    return (
                      <>
                        <th scope="row">{ind + 1}</th>
                        <td>{item.name}</td>
                        <td> ₹ {item.price}</td>
                      </>
                    );
                  })} */}
                </tr>
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceModal;
