import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { columns } from "./TableCol";
import { useParams } from "react-router-dom";
import HttpClient from "../../../utils/HttpClient";
import { toast } from "react-toastify";
const Index = () => {
  const { id } = useParams();
  const [serviceData, setServiceData] = useState([]);
  const [pending, setPending] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(
      `viewSalonAllService/${id}`,
      "GET",
      {}
    );

    if (result && result.status) {
      console.log(result, `result`);
      setPending(false);
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          serviceType: (
            <button
              onClick={() =>
                navigate("/service-type/" + item.serviceName, {
                  state: { service: item.serviceTypes },
                })
              }
              className="btn btn-primary"
            >
              service Type
            </button>
          ),
        };
      });
      setServiceData(arr); // set data
    } else {
      setPending(false);
      toast.error(result.message || result.error);
    }
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>View {} Services </h5>
      </div>
      {/* <FilterComponent
        state={sellsArr}
        setState={setSellsArr}
        constState={constState}
      /> */}
      <DataTable
        // title="Contact List"
        columns={columns}
        data={serviceData}
        progressPending={pending}
        pagination
      />
    </>
  );
};

export default Index;
