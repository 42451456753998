import React, { useRef, useState } from "react";
import HttpClient from "../../../utils/HttpClient";
import { toast } from "react-toastify";

const SaloonPaymentModel = ({ data }) => {

  const modalRef = useRef();

  console.log("data", data);
  const [amount, setAmount] = useState(
    data?.totalAmount == undefined ? 0 : data?.totalAmount
  );
  const [paymentType, setPaymentType] = useState("");

  const submit = async () => {
    let Data = {
      userId: data.userId,
      salonId: data.salonId,
      orderId: data.orderId,
      totalAmount: amount,
      paymentId: paymentType,
    };

    console.log("AAAAAAAA", JSON.stringify(Data), Data);
    if (Data.paymentId !== "" && Data.totalAmount > 0) {
      try {
        console.log("AAAAAAAA");
        let result = await HttpClient.requestData(
          "SalonPaymentByAdmin",
          "POST",
          Data
        );
        console.log("Response", result);
        // setAmount('');
        setPaymentType("");
        if (result && result.status) {
          toast.success(result.message);
          setTimeout(() => {
            modalRef.current.click();
          }, 1000);
        } else {
          console.log("llllllllllllllllllllll");
        }
      } catch (error) {
        console.log("catch");
        console.log(error);
      }
    } else {
      console.log("BBBBBBBBBBB");
      toast.error("please fill all the details");
    }
  };
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              saloon Payment by Admin
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              ref={modalRef}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form action="" onSubmit={() => submit()} className="px-5">
              {/* <input type="number" placeholder="Total Amount" value={ammount} onChange={(evt)=>setAmmount(evt.target.value)}/> */}
              <input
                type="number"
                placeholder="Total Amount"
                value={amount}
                onChange={(evt) => setAmount(evt.target.value)}
              />
              <br></br>
              <br></br>
              <input
                type="text"
                placeholder="Enter Payment type"
                value={paymentType}
                onChange={(evt) => setPaymentType(evt.target.value)}
              />
            </form>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button onClick={submit} type="button" className="btn btn-primary">
              submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaloonPaymentModel;
