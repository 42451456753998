export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Customer Name",
    selector: (row) => row.userName,
  },
  {
    name: "Customer Id",
    selector: (row) => row.userId,
    width: "250px",
  },
  {
    name: "Customer Number",
    selector: (row) => row.userMobile,
  },
  {
    name: "Customer Address",
    selector: (row) => row.userAddress,
  },
  {
    name: "View Orders",
    selector: (row) => row.action,
  },
];
