export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Service Name",
    selector: (row) => row.servicename,
  },
  {
    name: "Invoice Number",
    selector: (row) => row.orderId,
  },
  // {
  //   name: "Customer Id",
  //   selector: (row) => row._id,
  // },
  // {
  //   name: "User Name",
  //   selector: (row) => row.userData.name,
  // }, {
  //   name: "User Number",
  //   selector: (row) => row.userData.mobile,
  // },
  {
    name: "Start Price",
    selector: (row) => "₹" + row.startPrice,
  },  {
    name: "End Price",
    selector: (row) => "₹" + row.endPrice,
  },
  {
    name: "Booking Price",
    selector: (row) => "₹" + row.booking_price,
  },
  
  {
    name: "Date",
    selector: (row) => row.bookingdate,
  },
  {
    name: "Order Status",
    selector: (row) => row.orderStatus,
  },
  {
    name: "Booking Time",
    selector: (row) => row.bookingTime,
  },
];
