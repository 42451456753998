import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./TableCol";
import { IoMdDoneAll } from "react-icons/io";
var constState = null;
const Index = () => {
  const [issueList, setIssueList] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`viewSalonIssue`, "GET", {});

    if (result && result.status) {
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          createdDate: moment(item.createdOn).format("LL"),
          pending: (
            <button
              onClick={() => updateStatus(item._id)}
              className="btn btn-primary"
            >
              mark as resolved
            </button>
          ),
          solved: (
            <button
              onClick={() => updateStatus(item._id)}
              className="btn btn-primary"
              disabled
            >
              Resolved
            </button>
          ),
        };
      });
      constState = arr;
      setIssueList(arr); // set data
    } else {
      toast.error(result.message || result.error);
    }
  };
  const updateStatus = async (id) => {
    let res = await HttpClient.requestData(`issueStatusUpdate/${id}`, "PUT", {
      status: "completed",
    });
    if (res && res.status) {
      toast.success(res.message);
    } else {
      toast.error(res.message || res.error);
    }
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>View Saloon Issue</h5>
      </div>
      <FilterComponent
        constState={constState}
        state={issueList}
        setState={setIssueList}
      />
      <DataTable columns={columns} data={issueList} pagination />
    </>
  );
};

export default Index;
