import React from "react";

import { toast, ToastContainer } from "react-toastify";
// import HttpClient from "../../../utils/HttpClient";
import HttpClient from "../../../../utils/HttpClient";

const ImageUpload = ({ value, state, setState, label, ApiEndPoint }) => {
  const onImageUpload = (e) => {
    let file = e.target.files[0];
    imageUploadApi(file);
  };
  const imageUploadApi = async (file) => {
    let data = new FormData();
    data.append("image", file);
    let result = await HttpClient.fileUplode('subscription/uploadSubscriptionImage', "POST", data);
    console.log("SubsImage" , result);
    if (result && result.status) {
      setState({ ...state, image: result.url || result.image });
    } else {
      toast.error(result.message);
    }
  };
  const deleteImage = () => {
    setState({ ...state, image: "" });
  };
  return (
    <>
      <ToastContainer />
      <div
        className="position-relative mb-4 d-flex align-items-center justify-content-between"
        style={{ border: "1px solid #ced4da", padding: "1%" }}
      >
        <label>{label}</label>
        <label className="form_label d-flex align-items-center justify-content-between mb-2">
          Browse
          <input
            type="file"
            className="form-control"
            id="uploadFile"
            accept="image/*"
            onChange={(e)=>onImageUpload(e)}
          />
        </label>
      </div>
      {
        console.log("VALUE" , value)
      }
      {value !== "" ? (
        <div className="uploadingImage">
          <img
            src={value}
            alt=""
            className="img-fluid"
            style={{ margin: "10px", height: "100px", width: "100px" }}
          />
          <i
            onClick={() => deleteImage()}
            style={{ color: "red", cursor: "pointer" }}
            class="fa fa-trash"
            aria-hidden="true"
          ></i>
        </div>
      ) : null}
    </>
  );
};

export default ImageUpload;