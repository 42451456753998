import { Switch } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FilterComponent from "../../Common/SearchData/Index";
import HttpClient from "../../utils/HttpClient";
import PaymentModal from "./PaymentList/PaymentModal";
// import { columns } from "./TableCol";
var constSate = null;



const ViewAllSaloon = () => {
  const navigate = useNavigate();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [allSalon, setAllSaloon] = useState([]);
  const [modalData, setModalData] = useState();
  const [pending, setPending] = useState(true);


  const [model, setModel] = useState(false);
  const [imageSrc, setImagesrc] = useState("");

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Avatar",
      selector: (row) => row.image,
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Shop Name",
      selector: (row) => row.shopName,
    },
    {
      name: "Date of birth",
      selector: (row) => row.dob,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Number",
      selector: (row) => row.mobile,
    },
    {
      name: "Address",
      selector: (row) => row.address
    },
    {
      name: "Opening Time",
      selector: (row) => row.openingTime
    },
    {
      name: "Closing Time",
      selector: (row) => row.closingTime
    },
    {
      name: "Total Seats",
      selector: (row) => row.totalSeat
    },
    {
      name: "Pincode",
      selector: (row) => row.pinCode
    },
    {
      name: "State",
      selector: (row) => row.state
    },
    {
      name: "Pay",
      selector: (row) => row.pay,
    },
    {
      name: "Assured",
      selector: (row) => row.verified,
    },
    {
      name: "Status",
      selector: (row) => row.status,
    },
    {
      name: "View Services",
      selector: (row) => row.services,
    },
    {
      name: "View Staffs",
      selector: (row) => row.staff,
    },
    {
      name: "Action",
      selector: (row) => row.action,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const Close = () => {
    setModel(false);
    setImagesrc("");
  };
  const handleSaloonStuff = (id) => {
    navigate(`/saloon-staff/${id}`)
  }

  const Model = (src) => {
    setModel(true);
    setImagesrc(src);
  };

  const getData = async () => {
    let result = await HttpClient.requestData(`allSalonList`, "GET", {});
    console.log(result, "saloon");

    if (result && result.status) {
      console.log("SaloonList", result.data);

      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          isPay: false,
          status: <button className={`btn ${item.status ? "btn-success" : "btn-danger"}`} onClick={() => StatusChange(item)}>
            {item.status ? "Active" : "Deactive"}
          </button>,
          image: (
            <img onClick={() => Model(item?.image)} style={{ height: 38, width: 38, cursor: "pointer" }} src={item?.image} />
          ),
          //   pay: (
          //     <button
          //       onClick={() => setModalData({ id: item._id, name: item.name })}
          //       type="button"
          //       class="btn btn-primary"
          //       data-toggle="modal"
          //       data-target="#exampleModal"
          //     >
          //       pay
          //     </button>
          //   ),
          verified: <Switch
            checked={item.isVerified}
            // disabled={item.isVerified}
            onClick={() => {
              Verified(item)
            }}
            type="button"
          // data-toggle="modal"
          // data-target="#exampleModal"
          // {...label}
          />,
          pay: (
            <Switch
              checked={item.payment}
              disabled={item.payment}

              onClick={(e) => {
                setModalData({ id: item._id, name: item.name });
              }}
              type="button"
              data-toggle="modal"
              data-target="#exampleModal"
              {...label}
            />
          ),
          services: (
            <button
              onClick={() => navigate(`/saloon-service/${item._id}`)}
              className="btn btn-primary"
            >
              View
            </button>
          ),
          // staff: <button className="btn btn-success" onClick={() => navigate(`/saloon-staff/${item._id}`)}>
          //   View
          // </button>,
          staff: <button className="btn btn-success" onClick={() => handleSaloonStuff(item._id)}>
            View
          </button>,
          action: <button className="btn btn-success" onClick={() => navigate("/edit-saloon", { state: item })}>
            Edit
          </button>
        };

      });
      setPending(false);
      constSate = arr;
      console.log("ARR", arr);
      setAllSaloon(arr);
    } else {
      toast.error(result.message || result.error);
    }
  };

  const StatusChange = async (item) => {
    let result = await HttpClient.requestData(`updateSalonEnableStatus/${item._id}`, "PUT", { status: !item.status })
    if (result && result.status) {
      toast.success("Updated Successfully")
      getData()
    }
  }


  const Verified = async (item) => {
    let result = await HttpClient.requestData(`updateSalonProfile/${item._id}`, "PUT", { isVerified: !item.isVerified })
    if (result && result.status) {
      toast.success(result.message)
      getData()
    }

  }

  return (
    <>
      <div className="card-header pb-0">
        <h5>View All Saloon</h5>
      </div>
      <PaymentModal data={modalData} callback={getData} />
      <FilterComponent
        state={allSalon}
        setState={setAllSaloon}
        constState={constSate}
      />
      <DataTable
        progressPending={pending}
        columns={columns}
        data={allSalon}
        pagination
      />
      {model ? (
        <>
          <div
            tabIndex={-1}
            role="menu"
            aria-hidden="false"
            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
            data-popper-placement="bottom-end"
            style={{
              position: "absolute",
              inset: "14px 38px auto auto",
              transform: "translate(0px, 44px)",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{
                height: "400px",

              }}
              src={imageSrc}
            />
            {/* <button onClick={() => Close()}>x</button> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <svg
                onClick={() => Close()}
                style={{ color: "red", cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default ViewAllSaloon;
