import React from "react";

const UserInfo = ({ name, image, phone, email, adress, count, password }) => {
  return (
    <section id="view-seller">
      <div className="container-fluid">
        <div className="row mb-5">
          <div className="col-xl-12 col-lg-12 col-md-12 col-12">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                <div
                  className="cardimage m-auto"
                  style={{
                    width: "100%",
                    maxWidth: 300,
                    height: 300,
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                >
                  <img
                    className="card-img-top"
                    src={image}
                    alt="Card image cap"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                <div className="card p-4" style={{ width: "100%" }}>
                  <form>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="name"
                        className="block text-body-dark font-semibold text-sm leading-none mb-3"
                      >
                        Name
                      </label>
                      <input
                        disabled
                        id="name"
                        name="name"
                        type="text"
                        className="px-4 h-12"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        aria-invalid="false"
                        value={name}
                        style={{ width: "100%", height: 50 }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="phone"
                        className="block text-body-dark font-semibold text-sm leading-none mb-3"
                      >
                        Phone
                      </label>
                      <input
                        disabled
                        id="phone"
                        name="name"
                        type="number"
                        className="px-4 h-12"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        aria-invalid="false"
                        value={phone}
                        style={{ width: "100%", height: 50 }}
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="email"
                        className="block text-body-dark font-semibold text-sm leading-none mb-3"
                      >
                        Email
                      </label>
                      <input
                        disabled
                        id="email"
                        name="text"
                        type="email"
                        className="px-4 h-12"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        aria-invalid="false"
                        value={email}
                        style={{ width: "100%", height: 50 }}
                      />
                    </div>
                    {password && (
                      <div className="form-group mb-3">
                        <label
                          htmlFor="email"
                          className="block text-body-dark font-semibold text-sm leading-none mb-3"
                        >
                          Password
                        </label>
                        <input
                          disabled
                          id="email"
                          name="text"
                          type="email"
                          className="px-4 h-12"
                          autoComplete="off"
                          autoCorrect="off"
                          autoCapitalize="off"
                          spellCheck="false"
                          aria-invalid="false"
                          value={password}
                          style={{ width: "100%", height: 50 }}
                        />
                      </div>
                    )}

                    {count && (
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="sallon"
                              className="block text-body-dark font-semibold text-sm leading-none mb-3"
                            >
                              Sallon
                            </label>
                            <input
                              disabled
                              id="sallon"
                              name="text"
                              type="text"
                              className="px-4 h-12"
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              aria-invalid="false"
                              value={count[0]}
                              style={{ width: "100%", height: 50 }}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group mb-3">
                            <label
                              htmlFor="sallon"
                              className="block text-body-dark font-semibold text-sm leading-none mb-3"
                            >
                              Users
                            </label>
                            <input
                              disabled
                              id="sallon"
                              name="text"
                              type="text"
                              className="px-4 h-12"
                              autoComplete="off"
                              autoCorrect="off"
                              autoCapitalize="off"
                              spellCheck="false"
                              aria-invalid="false"
                              value={count[1]}
                              style={{ width: "100%", height: 50 }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {adress && (
                      <div className="form-group mb-3">
                        <label for="address">Address</label>
                        <textarea
                          disabled
                          class="form-control"
                          id="address"
                          rows="3"
                        >
                          Park street West Bengal ,700016
                        </textarea>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UserInfo;
