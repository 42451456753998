export const columns = [
    {
        name: 'Sl No',
        selector: row => row.sl,
    },{
        name: 'Name',
        selector: row => row.salon.name,
    },{
        name: 'Rating',
        selector: row => row.Rating,
    }
];
