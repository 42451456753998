import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useParams } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import moment from "moment";

function AttendCustomer() {
  const { id } = useParams();
  const [pending, setPending] = useState(false);
  const [staffs, setStaffs] = useState([]);

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    let result = await HttpClient.requestData(`userListAttendedBySpecialist/${id}`, "GET");
    console.log("res", result);
    if (result && result.status) {
      let arr = result.data.map((item, i) => {
        return {
          sl: i + 1,
          ...item,
          image: (
            <img src={item.userImage} style={{ height: "100px", width: "100px" }} />
          ),
          bookingDate:moment(item.bookingDate).format("ll")

        };
      });
      setStaffs(arr);
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Image",
      selector: (row) => row.image,
    },
    {
      name: "Name",
      selector: (row) => row.userName,
    },
    {
      name: "Gender",
      selector: (row) => row.userGender,
    },
    // {
    //   name: "D.O.B",
    //   selector: (row) => row.age,
    // },
    // {
    //   name: "Mobile",
    //   selector: (row) => row.mobile,
    // },
    // {
    //   name: "Salary",
    //   selector: (row) => row.salary,
    // },
    {
        name: "Service ",
        selector: (row) => row.serviceName
        ,
      },
      {
        name: "Booking Date ",
        selector: (row) => row.bookingDate
        ,
      },
      {
        name: "Booking Time ",
        selector: (row) => row.bookingTime
        ,
      },
    {
      name: "Fees ",
      selector: (row) => row.totalAmount,
    },
    // {
    //   name: "Status",
    //   selector: (row) => row.status,
    // },
    // {
    //   name: "Attend Customer",
    //   selector: (row) => row.attend_user,
    // },
  ];

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Attended Customer</h5>
      </div>

      <DataTable
        progressPending={pending}
        columns={columns}
        data={staffs}
        pagination
      />
    </>
  );
}

export default AttendCustomer;
