import React from "react";
import { BiTaxi } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Accordion } from "react-bootstrap";

const SideBarMenu = ({ data, subMenu, Hid, Cid, index }) => {
  return (
    <Accordion.Item eventKey={index}>
      <Accordion.Header> {data.icons} {data.menuName}</Accordion.Header>
      <Accordion.Body>
        <ul>
          {subMenu.map((item, ind) => {
            return (
              <li>
                <Link
                  to={item.linkTo}
                  className="nav-link menu-title waves-effect"
                >
                  {/* <i className="ri-dashboard-line"></i> */}
                  {item.icon}
                  <span className="ml-1">{item.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SideBarMenu;
