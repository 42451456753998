import React from "react";
import Dashboard from "../../Common/assets/images/dashboard/1.png";
import { Link } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import "react-dropdown/style.css";
import { RiDashboardLine } from "react-icons/ri";
import { FiMail } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { FiList } from "react-icons/fi";
import { BiTaxi } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { TbBrandBooking } from "react-icons/tb";
import { sideBarData } from "./SideBarData";
import SideBarMenu from "./SideBarMenu";
import { Accordion } from "react-bootstrap";
const SideBar = () => {
  const navigate = useNavigate();

  return (
    <div className="page-wrapper compact-wrapper" id="pageWrapper">
      <div className="page-body-wrapper sidebar-icon">
        <header className="main-nav">
          <div className="sidebar-user text-center">
            <a className="setting-primary" >
              <FiSettings />
            </a>
            <img className="img-90 rounded-circle" src={Dashboard} alt="img" />
            <div className="badge-bottom">
              <span className="badge badge-primary">New</span>
            </div>
            <a >
              <h6 className="mt-3 f-14 f-w-600">Admin</h6>
            </a>
            <p className="mb-0 font-roboto">KapeStar</p>
            {/* <ul>
                            <li><span><span className="counter">19.8</span>k</span>
                                <p>Follow</p>
                            </li>
                            <li><span>2 year</span>
                                <p>Experince</p>
                            </li>
                            <li><span><span className="counter">95.2</span>k</span>
                                <p>Follower </p>
                            </li>
                        </ul> */}
          </div>
          <nav>
            <div className="main-navbar">
              <div className="left-arrow" id="left-arrow">
                <i data-feather="arrow-left" />
              </div>
              <div id="mainnav">
                <ul className="nav-menu custom-scrollbar pl_ul">
                  <li className="back-btn">
                    <div className="mobile-back text-end">
                      <span>Back</span>
                      <i
                        className="fa fa-angle-right ps-2"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                  <li className="sidebar-main-title">
                    <div>
                      <h6>General </h6>
                    </div>
                  </li>

                  <div className="accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          onClick={() => navigate("/")}
                          className="accordion-button _sidebarbtn collapsed buttonhide"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          <RiDashboardLine /> Dashboard
                        </button>
                      </h2>
                    </div>

                    <Accordion defaultActiveKey="0">
                      {sideBarData.map((item, ind) => {
                        return (
                          <SideBarMenu
                            data={item}
                            Cid={`collapse${ind}`}
                            Hid={`heading${ind}`}
                            subMenu={item.subMenu}
                            index={ind}
                          />
                        );
                      })}
                    </Accordion>
                  </div>

                  {/* <li className="dropdown">
                                        <Link to="/news-categories" className="nav-link menu-title waves-effect">
                                            <i className="ri-dashboard-line"></i>
                                            <span className="ml-1">News Categories</span>
                                        </Link>
                                    </li>
                                    <li className="dropdown">
                                        <Link to="/news" className="nav-link menu-title waves-effect">
                                            <i className="ri-dashboard-line"></i>
                                            <span className="ml-1">News</span>
                                        </Link>
                                    </li> */}

                  {/* <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="layout" /><span>Page layout</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="box-layout.html">Boxed</a></li>
                                            <li><a href="layout-rtl.html">RTL</a></li>
                                            <li><a href="layout-dark.html">Dark</a></li>
                                            <li><a href="footer-light.html">Footer Light</a></li>
                                            <li><a href="footer-dark.html">Footer Dark</a></li>
                                            <li><a href="footer-fixed.html">Footer Fixed</a></li>
                                        </ul>
                                    </li>
                                    <li className="sidebar-main-title">
                                        <div>
                                            <h6>Components           </h6>
                                        </div>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="box" /><span>Ui Kits</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="state-color.html">State color</a></li>
                                            <li><a href="typography.html">Typography</a></li>
                                            <li><a href="avatars.html">Avatars</a></li>
                                            <li><a href="helper-classes.html">helper classes</a></li>
                                            <li><a href="grid.html">Grid</a></li>
                                            <li><a href="tag-pills.html">Tag &amp; pills</a></li>
                                            <li><a href="progress-bar.html">Progress</a></li>
                                            <li><a href="modal.html">Modal</a></li>
                                            <li><a href="alert.html">Alert</a></li>
                                            <li><a href="popover.html">Popover</a></li>
                                            <li><a href="tooltip.html">Tooltip</a></li>
                                            <li><a href="loader.html">Spinners</a></li>
                                            <li><a href="dropdown.html">Dropdown</a></li>
                                            <li><a href="according.html">Accordion</a></li>
                                            <li><a className="submenu-title" href="#">Tabs<span className="sub-arrow"><i className="fa fa-chevron-right" /></span></a>
                                                <ul className="nav-sub-childmenu submenu-content">
                                                    <li><a href="tab-bootstrap.html">Bootstrap Tabs</a></li>
                                                    <li><a href="tab-material.html">Line Tabs</a></li>
                                                </ul>
                                            </li>
                                            <li><a href="navs.html">Navs</a></li>
                                            <li><a href="box-shadow.html">Shadow</a></li>
                                            <li><a href="list.html">Lists</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="folder-plus" /><span>Bonus Ui</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="scrollable.html">Scrollable</a></li>
                                            <li><a href="tree.html">Tree view</a></li>
                                            <li><a href="bootstrap-notify.html">Bootstrap Notify</a></li>
                                            <li><a href="rating.html">Rating</a></li>
                                            <li><a href="dropzone.html">dropzone</a></li>
                                            <li><a href="tour.html">Tour</a></li>
                                            <li><a href="sweet-alert2.html">SweetAlert2</a></li>
                                            <li><a href="modal-animated.html">Animated Modal</a></li>
                                            <li><a href="owl-carousel.html">Owl Carousel</a></li>
                                            <li><a href="ribbons.html">Ribbons</a></li>
                                            <li><a href="pagination.html">Pagination</a></li>
                                            <li><a href="steps.html">Steps</a></li>
                                            <li><a href="breadcrumb.html">Breadcrumb</a></li>
                                            <li><a href="range-slider.html">Range Slider</a></li>
                                            <li><a href="image-cropper.html">Image cropper</a></li>
                                            <li><a href="sticky.html">Sticky       </a></li>
                                            <li><a href="basic-card.html">Basic Card</a></li>
                                            <li><a href="creative-card.html">Creative Card</a></li>
                                            <li><a href="tabbed-card.html">Tabbed Card</a></li>
                                            <li><a href="dragable-card.html">Draggable Card</a></li>
                                            <li><a className="submenu-title" href="#">Timeline<span className="sub-arrow"><i className="fa fa-chevron-right" /></span></a>
                                                <ul className="nav-sub-childmenu submenu-content">
                                                    <li><a href="timeline-v-1.html">Timeline 1</a></li>
                                                    <li><a href="timeline-v-2.html">Timeline 2</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="edit-3" /><span>Builders</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="form-builder-1.html">Form Builder 1</a></li>
                                            <li><a href="form-builder-2.html">Form Builder 2</a></li>
                                            <li><a href="pagebuild.html">Page Builder</a></li>
                                            <li> <a href="button-builder.html">Button Builder</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="cloud-drizzle" /><span>Animation</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="animate.html">Animate</a></li>
                                            <li><a href="scroll-reval.html">Scroll Reveal</a></li>
                                            <li><a href="AOS.html">AOS animation</a></li>
                                            <li><a href="tilt.html">Tilt Animation</a></li>
                                            <li><a href="wow.html">Wow Animation</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="command" /><span>Icons</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="flag-icon.html">Flag icon</a></li>
                                            <li><a href="font-awesome.html">Fontawesome Icon</a></li>
                                            <li><a href="ico-icon.html">Ico Icon</a></li>
                                            <li><a href="themify-icon.html">Thimify Icon</a></li>
                                            <li><a href="feather-icon.html">Feather icon</a></li>
                                            <li><a href="whether-icon.html">Whether Icon           </a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="cloud" /><span>Buttons</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="buttons.html">Default Style</a></li>
                                            <li><a href="buttons-flat.html">Flat Style</a></li>
                                            <li><a href="buttons-edge.html">Edge Style</a></li>
                                            <li><a href="raised-button.html">Raised Style</a></li>
                                            <li><a href="button-group.html">Button Group</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="bar-chart" /><span>Charts</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="chart-apex.html">Apex Chart</a></li>
                                            <li><a href="chart-google.html">Google Chart</a></li>
                                            <li><a href="chart-sparkline.html">Sparkline chart</a></li>
                                            <li><a href="chart-flot.html">Flot Chart</a></li>
                                            <li><a href="chart-knob.html">Knob Chart</a></li>
                                            <li><a href="chart-morris.html">Morris Chart</a></li>
                                            <li><a href="chartjs.html">Chatjs Chart</a></li>
                                            <li><a href="chartist.html">Chartist Chart</a></li>
                                            <li><a href="chart-peity.html">Peity Chart</a></li>
                                        </ul>
                                    </li>
                                    <li className="sidebar-main-title">
                                        <div>
                                            <h6>Forms</h6>
                                        </div>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="sliders" /><span>Form Controls              </span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="form-validation.html">Form Validation</a></li>
                                            <li><a href="base-input.html">Base Inputs</a></li>
                                            <li><a href="radio-checkbox-control.html">Checkbox &amp; Radio</a></li>
                                            <li><a href="input-group.html">Input Groups</a></li>
                                            <li><a href="megaoptions.html">Mega Options </a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="package" /><span>Form Widgets</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="datepicker.html">Datepicker</a></li>
                                            <li><a href="time-picker.html">Timepicker</a></li>
                                            <li><a href="datetimepicker.html">Datetimepicker</a></li>
                                            <li><a href="daterangepicker.html">Daterangepicker</a></li>
                                            <li><a href="touchspin.html">Touchspin</a></li>
                                            <li><a href="select2.html">Select2</a></li>
                                            <li><a href="switch.html">Switch</a></li>
                                            <li><a href="typeahead.html">Typeahead</a></li>
                                            <li><a href="clipboard.html">Clipboard </a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="layout" /><span>Form layout</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="default-form.html">Default Forms</a></li>
                                            <li><a href="form-wizard.html">Form Wizard 1</a></li>
                                            <li><a href="form-wizard-two.html">Form Wizard 2</a></li>
                                            <li><a href="form-wizard-three.html">Form Wizard 3</a></li>
                                        </ul>
                                    </li>
                                    <li className="sidebar-main-title">
                                        <div>
                                            <h6>Table</h6>
                                        </div>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="server" /><span>Bootstrap Tables           </span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="bootstrap-basic-table.html">Basic Tables</a></li>
                                            <li><a href="bootstrap-sizing-table.html">Sizing Tables</a></li>
                                            <li><a href="bootstrap-border-table.html">Border Tables</a></li>
                                            <li><a href="bootstrap-styling-table.html">Styling Tables</a></li>
                                            <li><a href="table-components.html">Table components</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="database" /><span>Data Tables </span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="datatable-basic-init.html">Basic Init</a></li>
                                            <li><a href="datatable-advance.html">Advance Init</a></li>
                                            <li><a href="datatable-styling.html">Styling</a></li>
                                            <li><a href="datatable-AJAX.html">AJAX</a></li>
                                            <li><a href="datatable-server-side.html">Server Side</a></li>
                                            <li><a href="datatable-plugin.html">Plug-in</a></li>
                                            <li><a href="datatable-API.html">API</a></li>
                                            <li><a href="datatable-data-source.html">Data Sources</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="hard-drive" /><span>Ex. Data Tables</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="datatable-ext-autofill.html">Auto Fill</a></li>
                                            <li><a href="datatable-ext-basic-button.html">Basic Button</a></li>
                                            <li><a href="datatable-ext-col-reorder.html">Column Reorder</a></li>
                                            <li><a href="datatable-ext-fixed-header.html">Fixed Header</a></li>
                                            <li><a href="datatable-ext-key-table.html">Key Table</a></li>
                                            <li><a href="datatable-ext-responsive.html">Responsive</a></li>
                                            <li><a href="datatable-ext-row-reorder.html">Row Reorder</a></li>
                                            <li><a href="datatable-ext-scroller.html">Scroller                    </a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="jsgrid-table.html"><i data-feather="file-text" /><span>Js Grid Table</span></a></li>
                                    <li className="sidebar-main-title">
                                        <div>
                                            <h6>Applications           </h6>
                                        </div>
                                    </li>
                                    <li className="dropdown">          <a className="nav-link menu-title" href="#"><i data-feather="box" /><span>Project              </span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="projects.html">Project List</a></li>
                                            <li><a href="projectcreate.html">Create new           </a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="file-manager.html"><i data-feather="git-pull-request" /><span>File manager</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="kanban.html"><i data-feather="monitor" /><span>kanban Board</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="shopping-bag" /><span>Ecommerce</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="product.html">Product</a></li>
                                            <li><a href="product-page.html">Product page</a></li>
                                            <li><a href="list-products.html">Product list</a></li>
                                            <li><a href="payment-details.html">Payment Details</a></li>
                                            <li><a href="order-history.html">Order History</a></li>
                                            <li><a href="invoice-template.html">Invoice</a></li>
                                            <li><a href="cart.html">Cart</a></li>
                                            <li><a href="list-wish.html">Wishlist</a></li>
                                            <li><a href="checkout.html">Checkout</a></li>
                                            <li><a href="pricing.html">Pricing</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="mail" /><span>Email</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="email_inbox.html">Mail Inbox</a></li>
                                            <li><a href="email_read.html">Read mail</a></li>
                                            <li><a href="email_compose.html">Compose</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="message-circle" /><span>Chat</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="chat.html">Chat App</a></li>
                                            <li><a href="chat-video.html">Video chat</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="users" /><span>Users</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="user-profile.html">Users Profile</a></li>
                                            <li><a href="edit-profile.html">Users Edit</a></li>
                                            <li><a href="user-cards.html">Users Cards</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="bookmark.html"><i data-feather="heart" /><span>Bookmarks</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="contacts.html"><i data-feather="list" /><span>Contacts</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="task.html"><i data-feather="check-square" /><span>Tasks</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="calendar-basic.html"><i data-feather="calendar" /><span>Calender      </span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="social-app.html"><i data-feather="zap" /><span>Social App</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="to-do.html"><i data-feather="clock" /><span>To-Do</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="search.html"><i data-feather="search" /><span>Search Result</span></a></li>
                                    <li className="sidebar-main-title">
                                        <div>
                                            <h6>Pages           </h6>
                                        </div>
                                    </li>
                                    <li><a className="nav-link menu-title link-nav" href="landing-page.html"><i data-feather="navigation-2" /><span>Landing page</span></a></li>
                                    <li><a className="nav-link menu-title link-nav" href="sample-page.html"><i data-feather="file" /><span>Sample page</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="internationalization.html"><i data-feather="aperture" /><span>Internationalization</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title link-nav" href="../starter-kit/index.html"><i data-feather="anchor" /><span>Starter kit </span></a></li>
                                    <li className="mega-menu"><a className="nav-link menu-title" href="#"><i data-feather="layers" /><span>Others</span></a>
                                        <div className="mega-menu-container menu-content">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col mega-box">
                                                        <div className="link-section">
                                                            <div className="submenu-title">
                                                                <h5>Error Page</h5>
                                                            </div>
                                                            <div className="submenu-content opensubmegamenu">
                                                                <ul>
                                                                    <li><a href="error-page1.html" target="_blank">Error page 1</a></li>
                                                                    <li><a href="error-page2.html" target="_blank">Error page 2</a></li>
                                                                    <li><a href="error-page3.html" target="_blank">Error page 3</a></li>
                                                                    <li><a href="error-page4.html" target="_blank">Error page 4                       </a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col mega-box">
                                                        <div className="link-section">
                                                            <div className="submenu-title">
                                                                <h5> Authentication</h5>
                                                            </div>
                                                            <div className="submenu-content opensubmegamenu">
                                                                <ul>
                                                                    <li><a href="login.html" target="_blank">Login Simple</a></li>
                                                                    <li><a href="login_one.html" target="_blank">Login with bg image</a></li>
                                                                    <li><a href="login_two.html" target="_blank">Login with image two                    </a></li>
                                                                    <li><a href="login-bs-validation.html" target="_blank">Login With validation</a></li>
                                                                    <li><a href="login-bs-tt-validation.html" target="_blank">Login with tooltip</a></li>
                                                                    <li><a href="login-sa-validation.html" target="_blank">Login with sweetalert</a></li>
                                                                    <li><a href="sign-up.html" target="_blank">Register Simple</a></li>
                                                                    <li><a href="sign-up-one.html" target="_blank">Register with Bg Image                            </a></li>
                                                                    <li><a href="sign-up-two.html" target="_blank">Register with Bg video                        </a></li>
                                                                    <li><a href="unlock.html">Unlock User</a></li>
                                                                    <li><a href="forget-password.html">Forget Password</a></li>
                                                                    <li><a href="creat-password.html">Creat Password</a></li>
                                                                    <li><a href="maintenance.html">Maintenance</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col mega-box">
                                                        <div className="link-section">
                                                            <div className="submenu-title">
                                                                <h5>Coming Soon</h5>
                                                            </div>
                                                            <div className="submenu-content opensubmegamenu">
                                                                <ul>
                                                                    <li><a href="comingsoon.html">Coming Simple</a></li>
                                                                    <li><a href="comingsoon-bg-video.html">Coming with Bg video</a></li>
                                                                    <li><a href="comingsoon-bg-img.html">Coming with Bg Image</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col mega-box">
                                                        <div className="link-section">
                                                            <div className="submenu-title">
                                                                <h5>Email templates</h5>
                                                            </div>
                                                            <div className="submenu-content opensubmegamenu">
                                                                <ul>
                                                                    <li><a href="basic-template.html">Basic Email</a></li>
                                                                    <li><a href="email-header.html">Basic With Header</a></li>
                                                                    <li><a href="template-email.html">Ecomerce Template</a></li>
                                                                    <li><a href="template-email-2.html">Email Template 2</a></li>
                                                                    <li><a href="ecommerce-templates.html">Ecommerce Email</a></li>
                                                                    <li><a href="email-order-success.html">Order Success    </a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="sidebar-main-title">
                                        <div>
                                            <h6>Miscellaneous           </h6>
                                        </div>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="image" /><span>Gallery</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="gallery.html">Gallery Grid</a></li>
                                            <li><a href="gallery-with-description.html">Gallery Grid Desc</a></li>
                                            <li><a href="gallery-masonry.html">Masonry Gallery</a></li>
                                            <li><a href="masonry-gallery-with-disc.html">Masonry with Desc</a></li>
                                            <li><a href="gallery-hover.html">Hover Effects</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="edit" /><span>Blog</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="blog.html">Blog Details</a></li>
                                            <li><a href="blog-single.html">Blog Single</a></li>
                                            <li><a href="add-post.html">Add Post</a></li>
                                        </ul>
                                    </li>
                                    <li><a className="nav-link menu-title link-nav" href="faq.html"><i data-feather="help-circle" /><span>FAQ</span></a></li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="user-check" /><span>Job Search</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="job-cards-view.html">Cards view</a></li>
                                            <li><a href="job-list-view.html">List View</a></li>
                                            <li><a href="job-details.html">Job Details</a></li>
                                            <li><a href="job-apply.html">Apply</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="layers" /><span>Learning</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="learning-list-view.html">Learning List</a></li>
                                            <li><a href="learning-detailed.html">Detailed Course</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="map" /><span>Maps</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="map-js.html">Maps JS</a></li>
                                            <li><a href="vector-map.html">Vector Maps</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="git-pull-request" /><span>Editors</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="summernote.html">Summer Note</a></li>
                                            <li><a href="ckeditor.html">CK editor</a></li>
                                            <li><a href="simple-MDE.html">MDE editor</a></li>
                                            <li><a href="ace-code-editor.html">ACE code editor</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown"><a className="nav-link menu-title" href="#"><i data-feather="database" /><span>Knowledgebase</span></a>
                                        <ul className="nav-submenu menu-content">
                                            <li><a href="knowledgebase.html">Knowledgebase</a></li>
                                            <li><a href="knowledge-category.html">Knowledge category</a></li>
                                            <li><a href="knowledge-detail.html">Knowledge detail</a></li>
                                        </ul>
                                    </li>
                                    <li><a className="nav-link menu-title link-nav" href="support-ticket.html"><i data-feather="headphones" /><span>Support Ticket</span></a></li> */}
                </ul>
              </div>
              <div className="right-arrow" id="right-arrow">
                <i data-feather="arrow-right" />
              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default SideBar;
