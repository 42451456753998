import React from "react";

const TradeLicesenceNumber = ({ state, setState, label,placeholder }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <span className="input-group-text">
        
        </span>
        <input
          className="form-control p_input"
          name="email"
          value={state.tradeLicense}
          onChange={(e) => {
            setState({
              ...state,
              tradeLicense: e.target.value,
            });
          }}
          type="text"
         
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default TradeLicesenceNumber;
