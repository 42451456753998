export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Name",
    selector: (row) => row.salonName,
  },
  {
    name: "Price",
    selector: (row) => row.price,
  },
  {
    name: "Status",
    selector: (row) => row.status,
  },
];
