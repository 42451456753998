import React from "react";

const Email = ({ label, state, setState,placeholder,req }) => {
  return (
    <div className="form-group">
      <label>{label}{req&&<span style={{color:"red"}}>*</span>}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="icon-email" />
        </span>
        <input
          className="form-control p_input"
          name="email"
          value={state.email}
          onChange={(e) => {
            setState({ ...state, email: e.target.value });
          }}
          // onBlur={()=>alert("veriffy email")}
          type="email"
          required
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Email;
