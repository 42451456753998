import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Login from "../Pages/Authentication/login";
import Dashboard from "../Pages/Dashboard/index";
import { reactLocalStorage } from "reactjs-localstorage";
import PrivateRoutes from "./PrivateRoutes";
import SellsDetails from "../Pages/Sells/SellsDetails/Index";
import Sells from "../Pages/Sells/ViewSells/Index";
import UserSaloonIndie from "../Pages/Sells/ViewUserSaloon/Index";
import RegisterSells from "../Pages/Sells/Register/Index";
import User from "../Pages/User/index";
import UserTrack from "../Pages/User/TrackUser/Index";
import AddSubscription from "../Pages/Subscription/AddSubs/Index";
import ViewSubs from "../Pages/Subscription/ViewSubs/Index";
import AddCoupon from "../Pages/Coupon/AddCoupon/Index";
import ViewCoupon from "../Pages/Coupon/ViewCoupon/Index";
import AddSaloon from "../Pages/Saloon/AddSaloon/Index";
import ViewSaloon from "../Pages/Saloon/ViewSaloon/Index";
import SaloonServices from "../Pages/Saloon/SaloonServices/Index";
import ServiceType from "../Pages/Saloon/SaloonServices/ServiceType/Index";
import ViewSaloonPayout from "../Pages/Saloon/SaloonPayout/Index";
import ViewAllSaloon from "../Pages/Payment/ViewAllSaloon";
import AddTarget from "../Pages/Saloon/ViewSaloon/AddTarget";
import SalonPaymentList from "../Pages/Payment/PaymentList/Index";
import ViewCoustomerRating from "../Pages/Ratings/Customer/Index";
import ViewSaloonRating from "../Pages/Ratings/Saloon/Index";
import ViewSpecialist from "../Pages/Ratings/Specialist/Index";
import UserSubscription from "../Pages/Subscription/UserSubscribtion/UserSubscription";
import ViewIssue from "../Pages/Issues/ViewIssue/Index";
import ServiceHistory from "../Pages/ServiceHistory/ViewServiceHistory/Index";
import Orders from "../Pages/ServiceHistory/Orders/Orders";
import Freelancer from "../Pages/Freelancer/Freelancer";
import NoPage from "../Common/NoPage";
import Catagory from "../Pages/Catagory"
import AddBanner from "../Pages/Banner/AddBanner"
import Booking from "../Pages/Bookings/index"
import PaidSaloon from "../Pages/Saloon/PaidSaloon/index"
import SalonStaff from "../Pages/SaloonStaff/index"
import AttendCustomer from "../Pages/SaloonStaff/AttendCustomer";
import CouponAddedBySalon from "../Pages/Coupon/ViewCoupon/CouponAddedBySalon";
import FreelancerTrack from "../Pages/Freelancer/FreelancerTrack";
import EditSaloon from "../Pages/Saloon/EditSaloon/EditSaloon";
import UsersCommision from "../Pages/UsersCommision/UsersCommision";
import UserCommisonDetails from "../Pages/UsersCommision/UserCommisonDetails";
import CommisionFromUser from "../Pages/CommisionFromUser/CommisionFromUser";

const Index = () => {
  return (
    <>
      {" "}
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dashboard />} />
            // Sells
            <Route path="/sells" element={<Sells />} />
            <Route path="/sells/:name" element={<SellsDetails />} />
            <Route path="/sells-register" element={<RegisterSells />} />
            <Route path="/:sells/:type/:name" element={<UserSaloonIndie />} />
            // User
            <Route path="/user" element={<User />} />
            <Route path="/catagory" element={<Catagory />} />
            <Route path="/user-track/:id" element={<UserTrack />} />
            <Route path="/users-commision" element={<UsersCommision />} />
            <Route path="/users-commision-details" element={<UserCommisonDetails />} />
            <Route path="/CommisionFromUser" element={<CommisionFromUser />} />

            // Subscription
            <Route path="/add-subscription" element={<AddSubscription />} />
            <Route path="/view-subscription" element={<ViewSubs />} />
            <Route path="/user-subscription" element={<UserSubscription />} />
            // Coupon
            <Route path="/add-coupons" element={<AddCoupon />} />
            <Route path="/view-coupons" element={<ViewCoupon />} />
            // Saloon
            <Route path="/add-saloon" element={<AddSaloon />} />
            <Route path="/view-added-saloon" element={<ViewSaloon />} />
            <Route path="/view-saloon-payout" element={<ViewSaloonPayout />} />
            <Route path="/add-target/:id" element={<AddTarget />} />
            <Route path="/saloon-service/:id" element={<SaloonServices />} />
            <Route path="/service-type/:name" element={<ServiceType />} />
            <Route path="/edit-saloon" element={<EditSaloon />} />

            <Route path="/paid-saloon" element={<PaidSaloon />} />
            <Route path="/saloon-staff/:id" element={<SalonStaff />} />





            //Freelancer
            <Route path="/manage-freelancer" element={<Freelancer />} />
            <Route path="/freelancer-track/:id" element={<FreelancerTrack />} />


            // Payment
            <Route path="/saloon-payment-list" element={<SalonPaymentList />} />
            <Route path="/view-all-saloon" element={<ViewAllSaloon />} />
            //Ratings
            <Route path="/customer-ratings" element={<ViewCoustomerRating />} />
            <Route path="/saloon-ratings" element={<ViewSaloonRating />} />
            <Route path="/specialist-ratings" element={<ViewSpecialist />} />
            //Issues
            <Route path="/saloons-issue" element={<ViewIssue />} />
            // Srvice History
            <Route path="/service-history" element={<ServiceHistory />} />
            <Route path="/service-history/:id" element={<Orders />} />
              //Banners
            <Route path="/banners" element={<AddBanner />} />
            <Route path="/booking-history" element={<Booking />} />
            <Route path="/attend-customer/:id" element={<AttendCustomer />} />
            <Route path="/view-coupons-added-by-salon" element={<CouponAddedBySalon />} />




          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Index;
