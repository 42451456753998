import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import HttpClient from '../../utils/HttpClient'
import DataTable from 'react-data-table-component'

const CommisionFromUser = () => {
    const [commisionData, setCommsionData] = useState([])
    const [totalPrice, setTotalPrice] = useState([])
    console.log("eeeeeeeeeeee", totalPrice)
    const location = useLocation()
    console.log("commisonLocation", location)

    const commsionColumn = [
        {
            name: "Sl No",
            selector: (row) => row.sl
        },
        {
            name: "Commision",
            selector: (row) => row.price
        },
        {
            name: "Subscription",
            selector: (row) => row.subscription
        },
    ]
    const commisionList = async () => {
        const res = await HttpClient.requestData(`seller/commission-from-user/642a66acc57204244d7fa8ec`, "GET")
        console.log("Comres", res)

        if (res && res?.status) {
            setTotalPrice(res)

            let arr = res?.data?.map((item, index) => {
                return {
                    sl: index + 1,
                    price: item?.price,
                    subscription: item?.subscription
                }
            })
            setCommsionData(arr)
        }
    }
    useEffect(() => {
        commisionList()
    }, [])
    return (
        <div>
            <DataTable columns={commsionColumn} data={commisionData} />
            <div className="card">
                <div className="card-body">
                    <span >Total Amount:{totalPrice?.totalPrice}</span>
                </div>
            </div>



        </div >
    )
}

export default CommisionFromUser