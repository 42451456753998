import React from "react";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  getZipCode,
getDetails  
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";

function Places({ setSearchPlace }) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (val) => {
    
    // console.log(val);
    setValue(val, false);
    clearSuggestions();
    let result = await getGeocode({ address: val });
    const { lat, lng } = await getLatLng(result[0]);
    const zipcode = await getZipCode(result[0])
    let stateArr = result[0].address_components.find((it) =>
    JSON.stringify(it).includes("administrative_area_level_1")
  );
    // const details = await getDetails(result[0].place_id)
    // console.log("postalCode",details);
    // console.log("detail",stateArr);

    setSearchPlace({address:val, lat, lng,zipcode ,state:stateArr?.long_name});
  };
  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        placeholder="Search Address"
        className="form-control"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <ComboboxPopover>
        <ComboboxList>
          {status === "OK" &&
            data.map(({ place_id, description }) => (
              <ComboboxOption key={place_id} value={description} />
            ))}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}

export default Places;
