import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import UserInfo from "../../../Common/Layout/DetailsPage/UserInfo";

const Index = () => {
  const [details, setDetails] = useState(useLocation().state);
  // console.log(details);
  return (
    <div>
      <UserInfo
        email={details?.email}
        name={details?.name}
        phone={details?.mobile}
        image={details?.image}
        adress={details?.address}
      />
    </div>
  );
};

export default Index;
