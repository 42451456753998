import moment from "moment";
import React from "react";

const FromDate = ({ label, state, setState, placeholder, editEnable }) => {
  // console.log("state.from", state);

  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="icon-phone" />
        </span>
        <input
          className="form-control p_input"
          name="dis"
          // value={state.from}
          value={moment(state.from).format('YYYY-MM-DD')}
          onChange={(e) => {
            setState({ ...state, from: e.target.value });
          }}
          type="date"
          required
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default FromDate;
