import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import HttpClient from "../../utils/HttpClient";
import { toast } from "react-toastify";

function AddSubscription({
  userId,
  subscription_details,
  SelectSubscription,
  close,
  getData,
}) {
  console.log("subs", subscription_details);
  const [subsList, setSubsList] = useState([]);

  useEffect(() => {
    fetchSubsList();
  }, []);

  const fetchSubsList = async () => {
    let result = await HttpClient.requestData(
      `subscription/viewAll`,
      "GET",
      {}
    );
    console.log("subslidt", result);
    if (result && result) {
      setSubsList(result.data);
    }
  };

  const AddSubToUser = async (item) => {
    SelectSubscription(item);
    // let data = {
    //   subcriptionId: item._id,
    //   userId: userId,
    //   duration: item.noOfDays,
    //   remainingUser: item.noOfPerson - 1,
    // };
    // console.log("i", data);
    // // return false
    // let result = await HttpClient.requestData(
    //   "subscription/adminBuySubscription",
    //   "POST",
    //   data
    // );
    // if (result && result.status) {
    //   toast.success("Subscription Added Successfully");
    // }
  };

  const CanCelSubsCriprion = async (subs_id) => {
    let data = {
      subcriptionId: subs_id,
      userId: userId,
    };

    let result = await HttpClient.requestData(
      "subscription/cancelSubscription",
      "PUT",
      data
    );
    if (result && result.status) {
      toast.success("Cancel Successfully");
      getData();
      close();
    } else {
      toast.error(result.message);
    }

    console.log("res", result);
  };

  return (
    <div
      className="modal fade show"
      style={{ display: "block", overflowY: "scroll" }}
      id="subsModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Subscription List
            </h5>
            <button
              type="button"
              className="close btn btn-danger"
              style={{ borderRadius: "50%" }}
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => close()}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <table
              className="table table-striped"
              style={{ overflow: "scroll" }}
            >
              <thead>
                <tr>
                  <th scope="col">Subscription Name</th>
                  <th scope="col">Select</th>
                  {/* <th scope="col">Gender</th>

                  <th scope="col"> Start Price</th>
                  <th scope="col"> End Price</th>
                  <th scope="col"> No. of Slot</th> */}
                </tr>
              </thead>
              <tbody>
                <>
                  {subsList.map((item) => {
                    return (
                      <tr>
                        <td>{item.name}</td>
                        <td>
                          {subscription_details &&
                          subscription_details?.sub?._id === item._id ? (
                            <button
                              className="btn btn-danger"
                              onClick={() => CanCelSubsCriprion(item._id)}
                            >
                              Cancel
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={() => AddSubToUser(item)}
                            >
                              Select
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                  {/* <th scope="row">1</th>
                  <td>{data?.serviceName}</td>
                  <td>{data?.gender}</td>

                  <td> ₹ {data?.startPrice}</td>
                  <td> ₹ {data?.endPrice}</td>
                  <td>{data?.noOfSlot}</td> */}
                </>

                {/* {data?.map((item, ind) => {
                  return (
                    <>
                      <th scope="row">{ind + 1}</th>
                      <td>{item.name}</td>
                      <td> ₹ {item.price}</td>
                    </>
                  );
                })} */}
              </tbody>
            </table>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSubscription;
