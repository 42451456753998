import React, { useState } from 'react'
import HttpClient from '../../utils/HttpClient';
import { toast } from "react-toastify";

function ConfirmSubscription({selectedSubscription,close,userId}) {

    let [startdate ,setStartDate] = useState("")

    const AddSubToUser = async () => {

      if(startdate ===""){
        toast.error("Please choose a date")
        return 
      }

       let item =  selectedSubscription
        let data = {
          subcriptionId: item._id,
          userId: userId,
          duration: item.noOfDays,
          remainingUser: item.noOfPerson - 1,
          startDate:startdate
        };
        console.log("i", data);
        // return false
        let result = await HttpClient.requestData(
          "subscription/adminBuySubscription",
          "POST",
          data
        );
        if (result && result.status) {
          toast.success("Subscription Added Successfully");
          close()
        }
        else{
            toast.error(result.message)
        }
      };
  return (
    <div
    className="modal fade show"
    style={{display:"block"}}
    id="confirmModal"
    tabIndex={-3}
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Confirm Subscription
          </h5>
          <button
            type="button"
            className="close btn btn-danger"
            data-dismiss="modal"
            aria-label="Close"
            style={{borderRadius:"50%"}}
            onClick={()=>close()}
          >
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
        <form>
  <fieldset disabled="">
    <div className="mb-3">
      <label htmlFor="disabledTextInput" className="form-label">
        Subscription Name
      </label>
      <input
        type="text"
        id="disabledTextInput"
        className="form-control"
        disabled
        value={selectedSubscription?.name}
        // placeholder="Disabled input"
      />
    </div>
    <div className="mb-3">
      <label htmlFor="disabledSelect" className="form-label">
        Select Start Subscription Date
      </label>
      <input
        type="date"
        id="disabledTextInput"
        className="form-control"
        value={startdate}
        onChange={(val)=>setStartDate(val.target.value)}
        // disabled
        // placeholder="Disabled input"
      />
    </div>
  
    <button type="button" onClick={AddSubToUser} className="btn btn-primary">
      Submit
    </button>
  </fieldset>
</form>

        </div>
     
      </div>
    </div>
  </div>  )
}

export default ConfirmSubscription