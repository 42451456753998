import { RiCoupon3Fill } from "react-icons/ri";
import { HiScissors } from "react-icons/hi";
import { MdPayment } from "react-icons/md";
import { FaUserAlt } from "react-icons/fa";
import { MdCategory } from "react-icons/md";
import { AiFillStar, AiOutlineHistory } from "react-icons/ai";
import { TbPremiumRights } from "react-icons/tb";
import { GoIssueOpened } from "react-icons/go";
import { SiFreelancer } from "react-icons/si"
import { GiVerticalBanner } from "react-icons/gi"
import { TbBrandBooking } from "react-icons/tb"


const salesLogo = "https://cdn-icons-png.flaticon.com/512/90/90466.png";

export const sideBarData = [
  {
    menuName: "Seller",
    // icons: <i class="fa-solid fa-user"></i>,
    icons: (
      <img style={{ height: 20, width: 20, marginRight: 5 }} src={salesLogo} />
    ),
    isClick: false,
    subMenu: [
      {
        name: "Register Seller",
        linkTo: "/sells-register",
      },
      {
        name: "View Sellers",
        linkTo: "/sells",
      },
    ],
  },
  {
    menuName: "Users",
    icons: <FaUserAlt />,
    isClick: false,
    subMenu: [
      {
        name: "View Users",
        linkTo: "/user",
      },
    ],
  },
  {
    menuName: "category",
    icons: <MdCategory />,
    isClick: false,
    subMenu: [
      {
        name: "View category",
        linkTo: "/catagory",
      },
    ],
  },

  {
    menuName: "Subscription",
    icons: <TbPremiumRights />,
    isClick: false,
    subMenu: [
      {
        name: "Add Subscription",
        linkTo: "/add-subscription",
      },
      {
        name: "View Added Subscription",
        linkTo: "/view-subscription",
      },
      {
        name: "Subscribed User",
        linkTo: "/user-subscription",
      },
    ],
  },
  {
    menuName: "Coupon",
    icons: <RiCoupon3Fill />,
    isClick: false,
    subMenu: [
      {
        name: "Add Coupons",
        linkTo: "/add-coupons",
      },
      {
        name: "View Coupons",
        linkTo: "/view-coupons",
      },
      {
        name: "View Coupons Added By Salon",
        linkTo: "/view-coupons-added-by-salon",
      },
    ],
  },
  {
    menuName: "Saloon",
    icons: <HiScissors />,
    isClick: false,
    subMenu: [
      {
        name: "Add Saloon",
        linkTo: "/add-saloon",
      },
      // {
      //   name: "View Added saloon",
      //   linkTo: "/view-added-saloon",
      // },
      {
        name: "View All saloon",
        linkTo: "/view-all-saloon",
      },
      {
        name: "Saloon Payout",
        linkTo: "/view-saloon-payout",
      },
      {
        name: "Paid Saloon",
        linkTo: "/paid-saloon"
      }
    ],
  },
  {
    menuName: "Freelancer",
    icons: <SiFreelancer />,
    isClick: false,
    subMenu: [
      {
        name: "Manage Freelancer",
        linkTo: "/manage-freelancer",
      },
    ],
  },
  {
    menuName: "Payment",
    icons: <MdPayment />,
    isClick: false,
    subMenu: [
      {
        name: "Paid Saloon List",
        linkTo: "/saloon-payment-list",
      },
    ],
  },
  {
    menuName: "Rating",
    icons: <AiFillStar />,
    isClick: false,
    subMenu: [
      {
        name: "Coustomer",
        linkTo: "/customer-ratings",
      },
      {
        name: "Saloon",
        linkTo: "/saloon-ratings",
      },
      {
        name: "Specialist",
        linkTo: "/specialist-ratings",
      },
    ],
  },
  {
    menuName: "Service History",
    icons: <AiOutlineHistory />,
    isClick: false,
    subMenu: [
      {
        name: "View Service History",
        linkTo: "/service-history",
      },
    ],
  },
  {
    menuName: "Issues",
    icons: <GoIssueOpened />,
    isClick: false,
    subMenu: [
      {
        name: "View Issues",
        linkTo: "/saloons-issue",
      },
    ],
  },
  {
    menuName: "Banners",
    icons: <GiVerticalBanner />,
    isClick: false,
    subMenu: [
      {
        name: "Add and Manage",
        linkTo: "/banners",
      },
    ],
  },
  {
    menuName: "Booking History",
    icons: <TbBrandBooking />,
    isClick: false,
    subMenu: [
      {
        name: "Bookings",
        linkTo: "/booking-history",
      },
    ],
  },
];
