import React from "react";

const GstNumber = ({ state, setState, label, placeholder }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <span className="input-group-text">
         
        </span>
        <input
          className="form-control p_input"
          name="text"
          value={state.gstNo}
          onChange={(e) => {
            setState({
              ...state,
              gstNo: e.target.value,
            });
          }}
          type="text"
         
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default GstNumber;
