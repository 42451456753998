import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HttpClient from "../../../../utils/HttpClient";
import { UserColumns } from "./UserCol";

const UserTable = ({ data, getMember, sellerName }) => {
  const navigate = useNavigate();
  let modArr = data?.map((it, ind) => {
    return {
      ...it,
      sl: ind + 1,
      date: moment(it.createdOn).format("DD-MMM-YYYY"),
      view: <button className="btn btn-primary" onClick={(e) => handleRowClicked(it, e)}>view</button>,
      action: it.approveStatus ? (
        <button
          onClick={(e) => {
            e.stopPropagation();
            updateStatus(!it.approveStatus, it._id, "userStatus");
          }}
          className="btn btn-danger"
        >
          Deactive
        </button>
      ) : (
        <button
          onClick={(e) => {
            e.stopPropagation();
            updateStatus(!it.approveStatus, it._id, "userStatus");
          }}
          className="btn btn-success"
        >
          approve
        </button>
      ),
    };
  });
  const updateStatus = async (status, id, type) => {
    // console.log("status,id", status, id, type);
    let result = await HttpClient.requestData(`seller/${type}/${id}`, "PUT", {
      approveStatus: status,
    });
    // console.log("result", result);
    if (result && result.status) {
      getMember();
    } else if (result == undefined) {
      toast.error("Something wrong!");
    } else {
      toast.error(result.message);
    }
  };
  const handleRowClicked = (data) => {
    console.log("data", data, sellerName);
    const { action, ...rest } = data;

    // /:sells/:type/:name
    navigate(`/${sellerName}/User/${data.name}`, { state: rest });
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>Users</h5>
      </div>
      <DataTable
        columns={UserColumns}
        data={modArr}
        pagination
        // onRowClicked={(row, e) => handleRowClicked(row, e)}
      />
    </>
  );
};

export default UserTable;
