export const columns = [
    {
        name: 'Sl No',
        selector: row => row.sl,
    },{
        name: 'Avatar',
        selector: row => <img style={{height:38,width:38}} src={row.salonData?.image}/> ,
    },{
        name: 'Name',
        selector: row => row.salonData?.name,
    },{
        name: 'Price',
        selector: row =>`Rs.${ row.totalAmount??"0"}`        ,
    },{
        name: 'Time',
        selector: row => row.bookingTime,
    },{
        name: 'Service',
        selector: row => row.services,
    },{
        name: 'Date',
        selector: row => row.date,
    },
];
