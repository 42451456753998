export const columns = [
    {
        name: 'Sl No',
        selector: row => row.sl,
    },{
        name: 'Name',
        selector: row => row.name,
    },{
        name: `Rating`,
        selector: row => row.Rating,
    }
];
