import React, { useEffect, useState } from 'react';
import Name from '../../Common/Layout/FormInput/Name';
import ImageUpload from '../../Common/Layout/FormInput/ImageUpload';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import HttpClient from '../../utils/HttpClient';
import DataTable from "react-data-table-component";
import Swal from 'sweetalert2';

const body = {
    name: "",
    image: "",
};


const Index = () => {
    const [location, setLocation] = useState(false);
    const [editId, setEditId] = useState('');
    const [formData, setFormData] = useState(location || body);
    const [catData, setCatData] = useState([]);
    const navigate = useNavigate()
    console.log('location', location);

    const submit = async (e) => {
        e.preventDefault();

        if (
            formData.name !== "" &&
            formData.image !== ""
        ) {
            if (location) {
                // While updating
                let res = await HttpClient.requestData(
                    `updateService/${editId}`,
                    "PUT",
                    { name: formData.name, icon: formData.image }
                );
                
                if (res && res.status) {
                    toast.success("Category Updated Successfully");
                    setFormData({ name: '', icon: '' });
                    setLocation(false);
                    fetchCatagory();
                    // setTimeout(() => {
                    //     // navigate('/view-coupons')
                    // }, 1000);
                } else {
                    toast.error(res.message);
                }
            } else {
                // While Creating
                let res = await HttpClient.requestData(
                    "addService",
                    "POST",
                    { name: formData.name, icon: formData.image });
                console.log('catagoryAdservice', res);
                if (res && res.status) {
                    toast.success(res.message);
                    setFormData({ name: '', icon: '' });
                    //   navigate("/view-coupons")
                } else {
                    toast.error("Category Added Fail");
                }
            }
        } else {
            toast.warn("All Fields Are Required !!!")
        }
    };

    const columns = [
        {
            name: 'Sl',
            selector: row => row.Sl,
        },
        {
            name: 'Name',
            selector: row => row.name,
        },
        {
            name: 'Icon',
            selector: row => row.icon,
        },
        {
            name: 'Edit',
            selector: row => row.edit
        },
        {
            name: 'Delete',
            selector: row => row.del
        }
    ];

    const fetchCatagory = async () => {
        let res = await HttpClient.requestData(`viewallServices`, "GET");
        console.log('tableData', res.data);

        if (res && res.status) {

            let arr = res.data.reverse().map((cat, i) => {
                return {
                    ...cat,
                    id: cat._id,
                    Sl: i + 1,
                    name: cat.name,
                    icon: <img src={cat.icon} height={70} width={70} />,
                    
                    edit: <button onClick={() => editCatagory(cat)} className="btn m-1 btn-sm btn-primary" >Edit</button>,
                    del:<button onClick={() => deleteCatagory(cat._id)} className="btn m-1 btn-sm btn-danger" >Delete</button>
                }
            });
            setCatData(arr);
        }
    }

    const editCatagory = (editData) => {

        setLocation(true);

        console.log('editedArray', editData);
        setFormData({ name: editData.name, image: editData.icon })
        // let arr=catData.filter(item=>item._id===editData._id)
        // console.log('editFormData',arr)
        window.scroll(0, 0);
        setEditId(editData._id)
    }

    const deleteCatagory=async(id)=>{

        // let res = await HttpClient.requestData(`viewallServices`, "GET");
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then(async(result) => {
            if (result.isConfirmed) {
            let res = await HttpClient.requestData(`deleteService/${id}`, "PUT");
            if(res && res.status){

            fetchCatagory();
              Swal.fire(
                'Deleted!',
                'Category has been deleted.',
                'success'
              )
            }else{
                Swal.fire(
                    'Failed!',
                    'Something Went Wrong',
                    'Error'
                  )
            }
            }
          })

    }

    console.log('catagoryData', catData);

    useEffect(() => {
        fetchCatagory();
    }, []);

    return (
        <>
            <form className="theme-form login-form" onSubmit={submit}>
                {/* <h4>{location ? "Update" : "Add"} Subscription</h4> */}
                <h6>{ !location ? 'Add' : 'Update'} Category information from here</h6>

                <Name
                    icons={false}
                    label="Category Name"
                    state={formData}
                    setState={setFormData}
                    placeholder="Enter Subscription Name"
                />

                <ImageUpload
                    label="Category Image"
                    value={formData.image ? formData.image : ""}
                    state={formData}
                    setState={setFormData}
                    ApiEndPoint="serviceImageUpload"
                />

                <div className="form-group">
                    <button className="btn btn-primary btn-block" type="submit">
                        {location ? "Update Category" : "  Add Category"}
                    </button>
                </div>
            </form>

            {/* DataTable */}
            <DataTable
                // progressPending={pending}
                columns={columns}
                data={catData}
                pagination
            />
        </>
    )
}

export default Index