export const columns = [
    {
        name: 'Sl No',
        selector: row => row.sl,
    },{
        name: 'Name',
        selector: row => row.name,
    },
    {
        name: 'Email',
        selector: row => row.email,
    }, {
        name: 'Date',
        selector: row => row.createDate,
    }, {
        name: 'Number',
        selector: row => row.mobile,
    },{
        name: 'Password',
        selector: row => row.password,
    },{
        name: 'Target',
        selector: row => row.target,
    },{
        name: 'Edit',
        selector: row => row.edit,
    },{
        name: 'Action',
        selector: row => row.deActive,
    },
];
