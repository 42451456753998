export const columns = [
  {
    name: "Order Id",
    selector: (row) => row.sl,
  },
  {
    name: "Customer Name",
    selector: (row) => row.userName,
  },
  {
    name: "Saloon Name",
    selector: (row) => row.saloonName,
    width: "250px",
  },
  {
    name: "Services",
    selector: (row) => row.services,
  },
  {
    name: "Booking Date",
    selector: (row) => row.bookingDate,
  },
  {
    name: "Saloon Rating",
    selector: (row) => row.rating,
  },
  // {
  //   name: "View Orders",
  //   selector: (row) => row.action,
  // },
];
