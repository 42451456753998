export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Image",
    selector: (row) => (
      <img style={{ height: 38, width: 38 }} src={row.salonImage} />
    ),
  },
  {
    name: "Name",
    selector: (row) => row.salonName,
  },
  {
    name: "Date",
    selector: (row) => row.createdDate,
  },
  {
    name: "Action",
    selector: (row) => (row.status === "pending" ? row.pending : row.solved),
  },
];
