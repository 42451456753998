import moment from "moment";
import React from "react";

const ToDate = ({ label, state, setState, placeholder }) => {
  const reg = /^[0-9]*$/;
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="icon-phone" />
        </span>
        <input
          className="form-control p_input"
          name="dis"
          value={moment(state.to).format("YYYY-MM-DD")}
          onChange={(e) => {
            setState({ ...state, to: e.target.value });
          }}
          type="date"
          required
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default ToDate;
