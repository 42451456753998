import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import { columns } from "./TableCol";

const Orders = () => {
  const location = useLocation().state?.order;
  console.log("location", location);
  const [orders, setOrders] = useState([]);
  useEffect(() => {
    let arr = location.map((item, ind) => {
      return {
        ...item,
        sl: ind + 1,
        bookingdate: moment(item.bookingDate).format("LL"),
        servicename: item.serviceTypes.serviceName,
        startPrice: item.serviceTypes.startPrice,
        endPrice: item.serviceTypes.endPrice,
        booking_price:item.actual_price??"0"
      };
    });
    setOrders(arr);
  }, []);

  // console.log("orders", orders);
  return (
    <>
      <div className="card-header pb-0">
        <h5>Orders History</h5>
      </div>
      {/* <FilterComponent
        state={serviceList}
        setState={setServiceList}
        constState={constState}
      /> */}
      <DataTable columns={columns} data={orders} pagination />
    </>
  );
};

export default Orders;
