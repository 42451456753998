import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import UserInfo from "../../../Common/Layout/DetailsPage/UserInfo";
import HttpClient from "../../../utils/HttpClient";
import { SaloonColumns } from "./SaloonView/SalonCol";
import SaloonTable from "./SaloonView/SaloonTable";
import { UserColumns } from "./USerView/UserCol";
import UserTable from "./USerView/UserTable";

const Index = () => {
  const [prop, setprop] = useState(useLocation().state);
  const [sellerDetails, setSellerDetails] = useState({});
  const navigate = useNavigate();
  // console.log(useLocation());
  useEffect(() => {
    getMember();
  }, []);
  const getMember = async () => {
    let res = await HttpClient.requestData("seller/sellerMemberList", "GET");
    // console.log(res, "memberlist");
    // setSellerDetails(res.data)
    let data = res.data.find((it) => it._id === prop._id);
    // console.log(data, "last1");
    setSellerDetails(data);
  };
  console.log("prop", prop);
  const style = { textAlign: "center", fontWeight: 600 };
  return (
    <>
      {/* <div className="card-header pb-0">
        <h5>View Seller</h5>
      </div> */}
      <UserInfo
        name={prop?.name}
        phone={prop?.mobile}
        image={prop?.image}
        email={prop?.email}
        password={prop?.password}
        count={[
          sellerDetails?.salonlist?.length,
          sellerDetails?.userlist?.length,
        ]}
      />
      <div className="m-5">
        <SaloonTable
          sellerName={prop?.name}
          getMember={getMember}
          data={sellerDetails?.salonlist}
        />
        <UserTable
          sellerName={prop?.name}
          getMember={getMember}
          data={sellerDetails?.userlist}
        />
      </div>
    </>
  );
};

export default Index;
