import React, { useState, useRef, useEffect } from "react";
import Header from "../../Common/Layout/header";
import SideBar from "../../Common/Layout/sideBar";
import Footer from "../../Common/Layout/footer";
import { MDBDataTable } from "mdbreact";
import JoditEditor from "jodit-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, useNavigate } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";
import DataTable from "react-data-table-component";
// import { columns } from "./TableCol";
import FilterComponent from "../../Common/SearchData/Index";
import AddSubscription from "./AddSubscription";
import ConfirmSubscription from "./ConfirmSubscription";
var constState = null;
const Users = () => {
  const [modalData, setModalData] = useState();
  const [customer, setCoustomer] = useState([]);
  const [orgArr, setOrgArr] = useState([]);
  const [userId, setuserId] = useState("");
  const [subscription_details, setSubscription_details] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [subsListModal, setSubsListModal] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const [model, setModel] = useState(false);
  const [imageSrc, setImagesrc] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    getData();
  }, []);

  const Model = (src) => {
    setModel(true);
    setImagesrc(src);
  };

  const Close = () => {
    setModel(false);
    setImagesrc("");
  };

  const columns = [
    {
      name: 'Sl No',
      selector: row => row.sl,
    }, {
      name: 'Avatar',
      selector: row => row.image,
    }, {
      name: 'Name',
      selector: row => row.name,
    },
    {
      name: 'Mobile',
      selector: row => row.mobile,
    },
    {
      name: 'Address',
      selector: row => row.address,
    },
    {
      name: 'Track',
      selector: row => row.track,
    },
    {
      name: 'Add Subscription',
      selector: row => row?.subscription,
    },
  ];
  const getData = async () => {
    let result = await HttpClient.requestData(`TrackCustomer`, "GET", {});

    if (result && result.status) {
      console.log(result, `result`);
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          subscription: (
            <button
              className="btn btn-primary"
              // data-toggle="modal"
              // data-target="#subsModal"
              // onClick={() => setModalData(item.serviceTypes)}
              onClick={
                () => {
                  setuserId(item._id);
                  setSubscription_details(item.subscription_details);
                  setSubsListModal(true);
                }
                // navigate("/user-track/" + item._id, { state: { item } })
              }
            >
              Add Subscription
            </button>
          ),
          image: (
            <img onClick={() => Model(item?.image)} style={{ height: 38, width: 38 }} src={item?.image} />
          ),
          track: (
            <button
              type="button"
              class="btn btn-primary"
              onClick={() =>
                navigate("/user-track/" + item._id, { state: { item } })
              }
            >
              view
            </button>
          ),
        };
      });
      constState = arr;
      setCoustomer(arr); // set data
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const SelectSubscription = (subscription) => {
    console.log("subbSata", subscription);
    setSelectedSubscription(subscription);
    setSubsListModal(false);

    setConfirmModal(true);
  };

  return (
    <>
      <FilterComponent
        state={customer}
        setState={setCoustomer}
        constState={constState}
      />
      <DataTable
        columns={columns}
        data={customer}
        // data={filteredItems}
        pagination
      />

      {model ? (
        <>
          <div
            tabIndex={-1}
            role="menu"
            aria-hidden="false"
            className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
            data-popper-placement="bottom-end"
            style={{
              position: "absolute",
              inset: "14px 38px auto auto",
              transform: "translate(0px, 44px)",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <img
              style={{
                height: "400px",

              }}
              src={imageSrc}
            />
            {/* <button onClick={() => Close()}>x</button> */}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <svg
                onClick={() => Close()}
                style={{ color: "red", cursor: 'pointer' }}
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                class="bi bi-x"
                viewBox="0 0 16 16"
              >
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
              </svg>
            </div>
          </div>
        </>
      ) : null}

      {subsListModal && (
        <AddSubscription
          subscription_details={subscription_details}
          userId={userId}
          SelectSubscription={SelectSubscription}
          close={() => setSubsListModal(false)}
          getData={getData}
        />
      )}

      {confirmModal && (
        <ConfirmSubscription
          userId={userId}
          selectedSubscription={selectedSubscription}
          close={() => setConfirmModal(false)}
        />
      )}
    </>
  );
};

export default Users;
