import React, { useState } from "react";
import { AiFillEye } from "react-icons/ai";

const Password = ({ label, state, setState, placeholder,req }) => {
  const [eye, setEye] = useState(false);
  return (
    <div className="form-group">
      <label>{label}{req&&<span style={{color:"red"}}>*</span>}</label>
      <div className="input-group" style={{ position: "relative" }}>
        <span className="input-group-text">
          <i className="icon-password" />
        </span>
        <input
          className="form-control p_input"
          name="password"
          value={state.password}
          onChange={(e) => {
            setState({ ...state, password: e.target.value });
          }}
          type={`${eye ? "text" : "password"}`}
          required
          placeholder={placeholder}
        />
      </div>
      <div
        className=""
        style={{
          position: "absolute",
          top: "35px",
          right: "15px",
          zIndex: "0",
        }}
        onClick={() => setEye(!eye)}
      >
        <AiFillEye />
      </div>
    </div>
  );
};

export default Password;
