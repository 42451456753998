import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./TableCol";
const Month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const d = new Date();
var constState = null;
const Index = () => {
  const [paymentList, setPaymentList] = useState([]);
  const [pending, setPending] = useState(true);
  const [thisMonth, setThisMonth] = useState(Month[d.getMonth()]);
  useEffect(() => {
    getData();
  }, [thisMonth]);
  const getData = async () => {
    let data = {
      month: thisMonth,
    };
    // console.log("data", data);
    let res = await HttpClient.requestData("getSalonPaymentList", "POST", data);
    if (res && res.status) {
      let arr = res.data.map((it, ind) => {
        return {
          ...it,
          sl: ind + 1,
          status:<button className="btn btn-primary">
{ it.salonPayment ?"Payment Done":"pending"}
          </button>
        };
      });
      setPending(false);
      constState = arr;
      setPaymentList(arr);
    }
  };

  return (
    <>
      <div className="card-header pb-0">
        <h5>View paymentList</h5>
        <button
          type="button"
          className="btn btn-danger dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {thisMonth}
        </button>
        <div className="dropdown-menu">
          {Month.map((item, ind) => {
            return (
              <a
                onClick={() => setThisMonth(item)}
                style={{ cursor: "pointer" }}
                className="dropdown-item"
              >
                {item}
              </a>
            );
          })}
        </div>
      </div>
      <FilterComponent
        state={paymentList}
        setState={setPaymentList}
        constState={constState}
      />
      <DataTable
        progressPending={pending}
        columns={columns}
        data={paymentList}
      />
    </>
  );
};

export default Index;
