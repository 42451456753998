import React, { useState } from "react";
import Header from "../../../Common/Layout/header";
import SideBar from "../../../Common/Layout/sideBar";
import Footer from "../../../Common/Layout/footer";
import { toast, ToastContainer } from "react-toastify";
import Name from "../../../Common/Layout/FormInput/Name";
import { useLocation, useNavigate } from "react-router-dom";
import MobileNumber from "../../../Common/Layout/FormInput/MobileNumber";
import Email from "../../../Common/Layout/FormInput/Email";
import Password from "../../../Common/Layout/FormInput/PassWord";
import ImageUpload from "../../../Common/Layout/FormInput/ImageUpload";
import HttpClient from "../../../utils/HttpClient";
import Otp from "./Comp/Otp";
const userData = {
  name: "",
  mobile: "",
  email: "",
  password: "",
  image: "",
  target: "",
};
const Index = () => {
  const navigate = useNavigate();
  const location = useLocation().state;
  const [formData, setFormData] = useState(location || userData);
  const [erorr, setError] = useState(userData);
  const [preview, setPreview] = useState("");
  const [isOtp, setIsOtp] = useState(false);
  const formValidation = (e) => {
    console.log(formData.mobile);
    e.preventDefault();
    if (`${formData.mobile}`.length !== 10) {
      setError({ ...erorr, mobile: "Enter 10 digit number" });
      return;
    } else {
      setError({ ...erorr, mobile: "" });
    }
    if (
      formData.name === "" ||
      formData.mobile === "" ||
      formData.email === "" ||
      formData.password === "" ||
      formData.image === ""
    ) {
      toast.warn("all Field are required!!!");
      console.log(formData.name,formData.mobile,formData.image);
      return;
    } else {
      submit();
    }
  };
  const submit = async () => {
    console.log("llll");
    let data = {
      name: formData.name,
      mobile: formData.mobile,
      email: formData.email,
      password: formData.password,
      image: formData.image,
    };

    console.log("Data", data);

    if (location) {
      
      let res = await HttpClient.requestData(
        "seller/adminupdateprofile/" + location._id,
        "PUT",
        data
      );
      console.log("AAAAAAAAAAAAAAAAA",res);
      // console.log("Res", res);
      if (res && res.status) {
        console.log(res, "rrr");
        toast.success("Seller Updated Successfully");
        setTimeout(() => {
          navigate("/sells");
        }, 2000);
      } else if (!res.status) {
        toast.error(res.message);
      }
    } else {
      let res = await HttpClient.requestData("seller/register", "POST", data);
      console.log("BBBBBBBBBBBB",res);
      // console.log("res", res);
      if (res && res.status) {
        // console.log(res, "rrr");
        toast.success("Seller Registration Successful");
        setTimeout(() => {
          navigate("/sells");
        }, 2000);
      } else if (!res.status) {
        toast.error(res.message);
      }
    }
    // console.log(data, "final");
  };
  // console.log("formData", location);
  const requestOtp = (e, number) => {
    e.preventDefault();
    setIsOtp(true);
  };
  return (
    <>
      <form className="theme-form login-form" onSubmit={formValidation}>
        <h4>Register Seller</h4>
        <h6>Add seller profile information from here</h6>
        <Name
          icons={true}
          label="Name"
          state={formData}
          setState={setFormData}
          placeholder="Enter Seller Name"
        />
        <MobileNumber
          label="Mobile Number"
          state={formData}
          setState={setFormData}
          placeholder="Enter Seller Number"
          erorr={erorr.mobile}
        />
        {/* {formData.mobile.length  === 10 && !isOtp ? (
          <button
            onClick={(e) => requestOtp(e, formData.mobile)}
            className="btn btn-primary mb-2"
          >
            Request Otp
          </button>
        ) : null}
        {isOtp ? <Otp /> : null} */}
        <Email
          label="Email Address"
          state={formData}
          setState={setFormData}
          placeholder="Enter Seller Email"
        />{" "}
        <Password
          label="Password"
          state={formData}
          setState={setFormData}
          placeholder="Enter Seller Password"
        />
        <ImageUpload
          value={formData.image}
          label="Upload Profile"
          state={formData}
          setState={setFormData}
          placeholder="Enter Seller Password"
          // ApiEndPoint="/seller/sellerImageUpload"
        />
        {
          console.log("Image", formData.image)
        }
        <div className="form-group">
          <button className="btn btn-primary" type="submit">
            {/* Register */}
            {location ? "Update" : "Register"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Index;
