export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Saloon Image",
    selector: (row) => (
      <img style={{ height: 38, width: 38 }} src={row.salonImage} />
    ),
  },
  {
    name: "Saloon Name",
    selector: (row) => row.salonName,
  },
  {
    name: "Price",
    selector: (row) => "₹"+row.price,
  },
  {
    name: "Date",
    selector: (row) => row.datee,
  }, {
    name: "User Image",
    selector: (row) =>  <img style={{ height: 38, width: 38 }} src={row.userImage} />,
  },
  {
    name: "User Name",
    selector: (row) => row.userName,
  },
];
