import React, { useEffect, useState } from "react";
import HttpClient from "../../../utils/HttpClient";
import DataTable from "react-data-table-component";
import SaloonPaymentModel from "./SaloonPaymentModel";
import { toast } from "react-toastify";

const Index = () => {
  const [allBookingList, setAllBookingList] = useState([]);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  const allReadyPaid = () => {
    toast.success("already payment done");
  };
  const test = [];
  const getData = async () => {
    let result = await HttpClient.requestData("view-all-bookingList", "GET");
    console.log("Result", result);
    if (result && result.status) {
      let arr = result.data.map((item, index) => {
        let value = item.list[0];
        const modalObj = {
          userId: value.userId,
          salonId: value.salonId,
          orderId: item._id.orderId,
          totalAmount: value?.totalAmount,
        };
  
        return {
          ...item,
          sl: index + 1,
          name: value.salon?.name,
          saloonOrder: value.orderStatus,
          totalpayment: value?.totalAmount,
          orderId: item._id.orderId,
          viewPayment:
            value.orderStatus == "completed" ? (
              <button onClick={() => allReadyPaid()} className="btn btn-danger">
                AllReady Paid
              </button>
            ) : (
              <button
                className="btn btn-primary"
                data-toggle="modal"
                data-target="#exampleModal"
                onClick={() => setModalData(modalObj)}
              >
                {" "}
                View
              </button>
            ),
        };
      });
      // console.log("ARR",arr);
      setAllBookingList(arr);
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },

    {
      name: "Saloon Name",
      selector: (row) => row.name,
    },
    {
      name: "Saloon Order Status",
      selector: (row) => row.saloonOrder,
    },
    {
      name: "Total Payment",
      selector: (row) => row.totalpayment,
    },

    {
      name: "Order ID",
      selector: (row) => row.orderId,
    },
    {
      name: "View Payment",
      selector: (row) => row.viewPayment,
    },
  ];

  return (
    <>
      <div className="card-header pb-0">
        <h5>View All BookingList</h5>
      </div>
      <SaloonPaymentModel data={modalData} callback={getData} />
      <DataTable columns={columns} data={allBookingList} pagination />
    </>
  );
};

export default Index;

