import moment from "moment";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../Common/Layout/header";
import SideBar from "../../../Common/Layout/sideBar";
import Footer from "../../../Common/Layout/footer";
import { ToastContainer } from "react-toastify";
import DataTable from "react-data-table-component";
import { columns } from "./TableRow";
import ServiceModal from "./ServiceModal";
const Index = () => {
  const location = useLocation().state.item;
  // console.log("location", location);
  const [booking, setBooking] = useState(location.booking);
  const [modalData, setModalData] = useState([]);
  useEffect(() => {
    let arr = location.booking.map((item, ind) => {
      console.log("item.serviceTypes", item.serviceTypes);
      return {
        ...item,
        sl: ind + 1,
        date: moment(item.bookingDate).format("LL"),
        services: (
          <button
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#exampleModal"
            onClick={() => setModalData(item.serviceTypes)}
          >
            view services
          </button>
        ),
      };
    });
    setBooking(arr);
  }, []);
  return (
    <>
      <ServiceModal data={modalData} />
      <DataTable columns={columns} data={booking} />
    </>
  );
};

export default Index;
