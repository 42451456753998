import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { BiSearchAlt2 } from "react-icons/bi";
// import SearchIcon from "@mui/icons-material/Search";

const FilterComponent = ({ constState, state, setState,key }) => {
   console.log("state", constState);

  const SearchData = (e) => {
    let word = e.target.value;
    console.log("word", word);
    if (!word) {
      setState(constState);
    } else {
      let fil = constState.filter((item) =>
        JSON.stringify(item).toLowerCase().includes(word.toLowerCase())
      );
      console.log("fil", fil);
      setState(fil);
    }
  };
  const style = {
    borderRadius: "10px",
    border: "2px solid black",
    padding: "5px",
  };
  return (
    <>
      <form className="form-inline">
        <div style={{ marginLeft: "auto" }} className="form-group mb-2">
          <input
            style={style}
            id="search"
            type="text"
            placeholder="Search"
            aria-label="Search Input"
            // value={filterText}
            onChange={SearchData}
          />
        </div>
      </form>

      {/* <input
        id="search"
        type="text"
        placeholder="Search"
        aria-label="Search Input"
        // value={filterText}
        onChange={SearchData}
      /> */}
    </>
  );
};

export default FilterComponent;
