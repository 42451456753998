import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./TableCol";
const Index = () => {
  const [payoutList, setPayoutList] = useState();
  const [pending, setPending] = useState(true);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`viewSalonPayout`, "GET", {});

    if (result && result.status) {
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          datee: moment(item.date).format("LL"),
        };
      });

      setPayoutList(arr); // set data
      setPending(false);
    } else {
      setPending(false);
      toast.error(result.message || result.error);
    }
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>View Saloon Payout</h5>
      </div>
      {/* <FilterComponent
        state={sellsArr}
        setState={setSellsArr}
        constState={constState}
      /> */}
      <DataTable
        // title="Contact List"
        columns={columns}
        data={payoutList}
        progressPending={pending}
        pagination
      />
    </>
  );
};

export default Index;
