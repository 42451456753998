import React, { useState } from "react";
import Header from "../../../Common/Layout/header";
import SideBar from "../../../Common/Layout/sideBar";
import Footer from "../../../Common/Layout/footer";
import { toast, ToastContainer } from "react-toastify";
import Name from "../../../Common/Layout/FormInput/Name";
import { useLocation, useNavigate } from "react-router-dom";
import Price from "./Component/Price";
import Swal from "sweetalert2";
import MonthDuration from "./Component/MonthDuration";
import Discount from "../../../Common/Layout/FormInput/Discount";
// import ImageUpload from "./FormComponent/ImageUpload";
import ImageUpload from "./Component/ImageUpload";
// import ImageUpload from
import HttpClient from "../../../utils/HttpClient";
const body = {
  name: "",
  price: "",
  noOfDays: "",
  additionalDays: "",
  noOfPerson: "",
  // discount: "",
  desc: [],
  image: "",
  priority: 0,
};
var count = 0;
const Index = () => {
  const location = useLocation().state;
  const navigate = useNavigate();
  // console.log("location", location);
  const [createSub, setCreateSub] = useState(location || body);

  console.log("createSub", createSub);
  const addDesc = (e) => {
    e.preventDefault();
    let arr = [...createSub.desc];
    count = count + 1;
    arr.push("");
    setCreateSub({ ...createSub, desc: arr });
  };
  // console.log(createSub);
  const removeHighlight = (ind) => {
    let arr = [...createSub.desc];
    arr.splice(ind, 1);
    setCreateSub({ ...createSub, desc: arr });
  };
  const addSubscription = async (e) => {
    e.preventDefault();
    if (
      createSub.name === "" ||
      createSub.price === "" ||
      createSub.noOfDays === "" ||
      // createSub.duration === "" ||
      createSub.noOfPerson === "" ||
      !createSub.priority
      // createSub.image ===""
      // createSub.desc.l
    ) {
      toast.warn("all field are required !!!");
    } else {
      if (createSub.priority <= 0) {
        toast.error("Priority should be positive number");
        return;
      }
      console.log(createSub);
      // return false
      if (location) {
        let data = createSub;

        if (createSub.priority === location?.priority) {
          delete data.priority;
        }

        let res = await HttpClient.requestData(
          `subscription/update/${location._id}`,
          "PUT",
          data
        );
        console.log("ResEdit", res);
        if (res && res.status) {
          toast.success("Subscription Updated Successfully");
          setTimeout(() => {
            navigate("/view-subscription");
          }, 1000);
        } else {
          toast.error(res.error || res.message);
        }
      } else {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't to add this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, Add it!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            let res = await HttpClient.requestData(
              "subscription/create",
              "POST",
              createSub
            );
            console.log("EEEEEEEEEE", res)
            if (res && res.status) {
              toast.success("subscription added !");
              setTimeout(() => {
                navigate("/view-subscription");
              }, 1000);
            } else {
              toast.error(res.message);
            }
          }
        });
      }
    }
  };
  return (
    <>
      <form className="theme-form login-form" onSubmit={addSubscription}>
        <h4>{location ? "Update" : "Add"} Subscription</h4>
        <h6>Add Subscription information from here</h6>
        <Name
          icons={false}
          label="Name"
          state={createSub}
          setState={setCreateSub}
          placeholder="Enter Subscription Name"
        />
        <Price
          label="Price"
          state={createSub}
          setState={setCreateSub}
          placeholder="Enter Subscription Price"
        />
        <MonthDuration state={createSub} setState={setCreateSub} />
        <Discount
          label="Additional Days"
          state={createSub}
          setState={setCreateSub}
          placeholder="Do you want to add additional days?"
        />
        <div className="form-group">
          <label>Number of persons</label>
          <div className="input-group">
            <span className="input-group-text">
              <i className="icon-phone" />
            </span>
            <input
              className="form-control p_input"
              name="dis"
              value={createSub.noOfPerson}
              onChange={(e) => {
                // if (reg.test(e.target.value)) {
                setCreateSub({ ...createSub, noOfPerson: e.target.value });
                // }
              }}
              type="number"
              required
              placeholder="persons allowed"
            />
          </div>
        </div>
        <ImageUpload
          state={createSub}
          setState={setCreateSub}
          label="Subscription Image"
          value={createSub.image}
          type="sub"
        />{" "}
        <div className="form-group">
          <button
            onClick={(e) => {
              e.preventDefault();
              addDesc(e);
            }}
            className="btn btn-warning my-3 mr-3"
          >
            Add Description
          </button>
          {createSub.desc.map((item, ind) => {
            return (
              <>
                <div className="form-group mb-4" style={{ display: "flex" }}>
                  <input
                    className="form-control"
                    value={item}
                    onChange={(e) => {
                      let arr = [...createSub.desc];
                      arr[ind] = e.target.value;
                      setCreateSub({ ...createSub, desc: arr });
                    }}
                    type="text"
                  />
                  <button
                    onClick={() => removeHighlight(ind)}
                    type="submit"
                    className="btn btn-danger m-1"
                  >
                    X
                  </button>
                </div>
              </>
            );
          })}
        </div>
        <div className="form-group">
          <label>Subscription Priority</label>
          <div className="input-group">
            <span className="input-group-text">
              <i className="icon-phone" />
            </span>
            <input
              className="form-control p_input"
              name="dis"
              value={createSub.priority}
              onChange={(e) => {
                // if (reg.test(e.target.value)) {
                setCreateSub({
                  ...createSub,
                  priority: parseInt(e.target.value),
                });
                // }
              }}
              type="number"
              required
              placeholder="priority"
            />
          </div>
        </div>
        <div className="form-group">
          <button className="btn btn-primary btn-block" type="submit">
            {location ? "Update Subscription" : "  Add SubsCription"}
          </button>
        </div>
      </form>
    </>
  );
};

export default Index;
