import { Button, Switch } from "@mui/material";

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Swal from "sweetalert2";
import FilterComponent from "../../Common/SearchData/Index";
import Httpclient from "../../utils/HttpClient";
import { appCol } from "./AppCol";
import { pendingCol } from "./PendinCol";
import HttpClient from "../../utils/HttpClient";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Freelancer = () => {
  const navigate = useNavigate();
  const [pendConst, setpendConst] = useState([]);
  const [appConst, setappConst] = useState([]);
  const [pendingLancer, setPendingLancer] = useState([]);
  const [approved, setApproved] = useState([]);
  useEffect(() => {
    getPending();
    getApprvd();
  }, []);
  const getPending = async () => {
    let res = await Httpclient.requestData("viewAllFreelancer", "POST", {
      jobStatus: "pending",
    });
    console.log("freeRes", res);
    if (res && res.status) {
      let arr = [];
      arr = res.data.map((item, ind) => {
        return {
          // ...item?.free[0],
          name: item?.free[0].name,
          email: item?.free[0].email,
          mobile: item?.free[0].mobile,
          expert: item?.free[0].expert?.map((item) => item.name),
          sl: ind + 1,
          action: (
            <Button
              size="small"
              onClick={() => {
                confirm(item.free[0]);
              }}
              variant="contained"
              color="success"
            >
              approve
            </Button>
          ),
        };
      });
      setpendConst(arr);
      setPendingLancer(arr);
    }
  };
  const getApprvd = async () => {
    let res = await Httpclient.requestData("viewAllFreelancer", "POST", {
      jobStatus: "",
    });
    console.log("Response", res);
    if (res && res.status) {
      let arr = [];
      let arr1 = [];
      let data = {
        sl: "",
        name: "",
        email: "",
        expert: [],
        mobile: "",
        action: "",
        track: "",
      };

      res.data.map((item, index) => {
        let value = item.free[0];
        data.sl = index + 1;
        data.name = value.name;
        data.email = value.email;
        data.mobile = value.mobile;
        data.action = (
          <>
            {value.block ? (
              <button
                className="btn btn-danger"
                onClick={() => blockingHandle(value._id, !value.block)}
              >
                Unblock
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => blockingHandle(value._id, !value.block)}
              >
                Block
              </button>
            )}
          </>
        );
        value.expert.map((val) => {
          arr1.push(val.name, ",");
        });
        data.expert = arr1;
        data.track = (
          <button
            className="btn btn-success"
            onClick={() => navigate(`/freelancer-track/${value._id}`)}
          >
            Track
          </button>
        );
        console.log("AA", arr1);
        arr.push({ data, ...data });
        arr1 = [];
      });
      setappConst(arr);
      setApproved(arr);
    }
  };

  const confirm = (data) => {
    Swal.fire({
      title: `Approve ${data.name} ?`,
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Approve Freelancer!",
    }).then((result) => {
      if (result.isConfirmed) {
        approveFreel(data._id, data.name);
      }
    });
  };
  const approveFreel = async (id, name) => {
    let res = await Httpclient.requestData(
      "updateFreelancerStatus/" + id,
      "PUT",
      { jobStatus: "readyForService" }
    );
    if (res && res.status) {
      Swal.fire("Approved!", name + "has been approved!!!", "success");
      getPending();
      getApprvd();
    }
    console.log("res", res);
  };

  const blockingHandle = async (id, status) => {
    let data = await HttpClient.requestData(
      `updateFreelancerStatus/${id}`,
      "PUT",
      { block: status }
    );
    if (data && data.status) {
      toast.success("Updated Successfully");
      getApprvd();
    } else {
      toast.error(data.message);
    }
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>Pending Freelancer</h5>
      </div>
      <FilterComponent
        state={pendingLancer}
        setState={setPendingLancer}
        constState={pendConst}
      />
      <DataTable
        columns={pendingCol}
        data={pendingLancer}
        pagination
        //   onRowClicked={(row, e) => handleRowClicked(row, e)}
      />{" "}
      <div className="card-header pb-0">
        <h5>Approved Freelancer</h5>
      </div>
      <FilterComponent
        state={approved}
        setState={setApproved}
        constState={appConst}
      />
      <DataTable
        columns={appCol}
        data={approved}
        pagination
        //   onRowClicked={(row, e) => handleRowClicked(row, e)}
      />
    </>
  );
};

export default Freelancer;
