import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
// import { columns } from "./TableCol";
var constState = null;
const Index = () => {
  const navigate = useNavigate();
  const [coupons, setCoupons] = useState([]);
    const [model, setModel] = useState(false);
  const [imageSrc, setImagesrc] = useState("");
  useEffect(() => {
    getData();
  }, []);

   const Model = (src) => {
    setModel(true);
    setImagesrc(src);
  };

  const Close = () => {
    setModel(false);
    setImagesrc("");
  };


  const columns = [
    {
        name: 'Sl No',
        selector: row => row.sl,
    },{
        name: 'Image',
        selector: row => row.image,
    },
    {
        name: 'Name',
        selector: row => row.name,
    }, {
        name: 'Discount',
        selector: row => row.discount + "%",
    }, {
        name: 'From',
        selector: row => row.fromDate,
    },{
        name: 'To',
        selector: row => row.toDate,
    },
  
    {
        name: 'Action',
        selector: row => row.action,
    },
];
  const getData = async () => {
    let result = await HttpClient.requestData(`viewAllCoupon`, "GET", {});
    // console.log("result", result);
    if (result && result.status) {
      let arr = result.data.map((it, ind) => {
        return {
          ...it,
          sl: ind + 1,
          fromDate: moment(it.from).format("LL"),
          toDate: moment(it.to).format("LL"),
          image:(
            <img onClick={() => Model(it?.image)} style={{height:38,width:38}} src={it?.image}/>
          ),
          action: (
            <button
              onClick={() => updateCoupon(it)}
              className="btn btn-primary"
            >
              {" "}
              update
            </button>
          ),
        };
      });
      constState = arr;
      setCoupons(arr);
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const updateCoupon = (data) => {
    navigate("/add-coupons", { state: data });
  };

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Coupons</h5>
      </div>
      <FilterComponent
      
        constState={constState}
        state={coupons}
        setState={setCoupons}
      />
      <DataTable columns={columns} data={coupons} pagination />
               {model ? (
            <>
              <div
                tabIndex={-1}
                role="menu"
                aria-hidden="false"
                className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                data-popper-placement="bottom-end"
                style={{
                  position: "absolute",
                  inset: "14px 38px auto auto",
                  transform: "translate(0px, 44px)",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  style={{
                    height: "400px",
       
                  }}
                  src={imageSrc}
                />
                {/* <button onClick={() => Close()}>x</button> */}
                <div style={{display:'flex' , justifyContent:'center'}}>
                  <svg
                    onClick={() => Close()}
                    style={{ color: "red" , cursor:'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                </div>
              </div>
            </>
          ) : null}
    </>
  );
};

export default Index;
