import React, { useEffect, useMemo, useState } from "react";
// import { toast, ToastContainer } from "react-toastify";
import toast from "react-hot-toast";
import Header from "../../../Common/Layout/header";
import SideBar from "../../../Common/Layout/sideBar";
import Footer from "../../../Common/Layout/footer";
import DataTable from "react-data-table-component";
import { columns } from "./TableCol";
import HttpClient from "../../../utils/HttpClient";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import moment from "moment";
import FilterComponent from "../../../Common/SearchData/Index";
import { Button } from "react-bootstrap";
import { MdRemoveDone } from "react-icons/md";
import { FcApproval, FcDisapprove } from "react-icons/fc";
var constState = null;
const Index = () => {
  const [sellsArr, setSellsArr] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getSellerList();
  }, []);
  const getSellerList = async () => {
    let result = await HttpClient.requestData(
      "seller/viewAllSellerProfile",
      "GET",
      {}
    );
    console.log("RES", result);
    // console.log(result);
    if (result && result.status) {
      let revArr = result.data.reverse();
      let res = revArr.map((element, ind) => {
        return {
          ...element,
          sl: ind + 1,
          edit: (
            <button
              onClick={() => navigate("/sells-register", { state: element })}
              className="btn btn-primary"
            >
              edit
            </button>
          ),
          deActive: (
            <Button
              onClick={(e) =>
                activeDeactiveseller(element._id, element.enableStatus, e)
              }
              // className={`btn btm-sm ${
              //   element.enableStatus ? "btn-danger" : "btn-success"
              // }`}
              variant={`${element.enableStatus ? "danger" : "success"}`}
              size="sm"
            >
              {element.enableStatus ? "De-Active" : "Active"}
            </Button>
          ),
          active: (
            <button
              onClick={(e) =>
                activeDeactiveseller(element._id, element.enableStatus, e)
              }
              className="btn btn-success"
            >
              Approve
            </button>
          ),
          target: (
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/add-target/${element.name}`, { state: element });
              }}
              className="btn btn-primary"
            >
              View
            </button>
          ),
          viewtarget: (
            <div>
              <button type="button" className="btn m-1 btn-sm btn-info">
                Saloons <span className="badge badge-light">0</span>
              </button>
              <br />
              <button type="button" className="btn m-1 btn-sm btn-success">
                Users <span className="badge badge-light">0</span>
              </button>
            </div>
          ),
          createDate: moment(element.createdOn).format("DD-MMM-YYYY"),
        };
      });
      // console.log('res', res)
      // console.log('res.re', res.reverse())
      constState = res;
      setSellsArr(res);
    }else{
      toast.error(result.message)
    }
  };

  const activeDeactiveseller = async (id, status, e) => {
    console.log("first", status);
    e.stopPropagation();
    let result = await HttpClient.requestData(
      `seller/changeStatus/${id}`,
      "PUT",
      {
        enableStatus: !status,
      }
    );
    console.log(result);
    if (result && result.status) {
      getSellerList();
      toast(`${!status?"Activated !!!":"De-activated !!!"}`, {
        duration: 4000,
        position: "top-center",

        // Styling
        style: {},
        className: "",

        // Custom Icon
        icon: !status? <FcApproval/>:<FcDisapprove/>,

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },

        // Aria
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    } else {
      toast.error(result?.message || result?.error);
    }
  };
  const handleRowClicked = (row, e) => {
    const { active, deActive, edit, target, viewtarget, ...rest } = row;
    let data = rest;
    // console.log('data', data)
    // console.log('row ,e', row ,e)
    navigate("/sells/" + row._id, { state: data });
  };
  // console.log('sellyysArr', sellsArr)
  return (
    <>
      <div className="card-header pb-0">
        <h5>View Seller</h5>
      </div>
      <FilterComponent
        state={sellsArr}
        setState={setSellsArr}
        constState={constState}
      />
      <DataTable
        columns={columns}
        data={sellsArr}
        pagination
        onRowClicked={(row, e) => handleRowClicked(row, e)}
      />
    </>
  );
};

export default Index;
