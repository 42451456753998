import React, { useState, useRef, useEffect } from "react";
import Header from "../../Common/Layout/header";
import SideBar from "../../Common/Layout/sideBar";
import Footer from "../../Common/Layout/footer";
import { MDBDataTable } from "mdbreact";
import JoditEditor from "jodit-react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



import axios from "axios";
import DataTable from "react-data-table-component";

import FilterComponent from "../../Common/SearchData/Index";
import AddSubscription from "../User/AddSubscription";
import ConfirmSubscription from "../User/ConfirmSubscription";
var constState = null;
const UserCommisonDetails = () => {
    const [modalData, setModalData] = useState();
    const [customer, setCoustomer] = useState([]);
    const [orgArr, setOrgArr] = useState([]);
    const [userId, setuserId] = useState("");
    const [subscription_details, setSubscription_details] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [subsListModal, setSubsListModal] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [sellerData, setSellerData] = useState([])
    const [price, setPrice] = useState([]);

    const [data , setData] = useState([]);
    console.log('price', price)


    const [model, setModel] = useState(false);
    const [imageSrc, setImagesrc] = useState("");
    const location = useLocation()
    // console.log('Location', location)


    const navigate = useNavigate();

    useEffect(() => {
        
        if(location.pathname === '/users-commision-details'){

            console.log('Location', location)
            // setCoustomer(location?.state);
            getSellerData (location?.state);
            
        }

        
           
       
    }, []);


    const Model = (src) => {
        setModel(true);
        setImagesrc(src);
    };

    const Close = () => {
        setModel(false);
        setImagesrc("");
    };

    const columns = [
        {
            name: 'Sl No',
            selector: row => row.sl,
        },
        {
            name: 'Price ',
            selector: row => row.price,
        },
        {
            name: 'Subscription',
            selector: row => row.subscription,
        }
    ];


    const getSellerData = (data) => {
        console.log("SELLDATA",data);
        let arr = [];
        data.forEach(element =>{
            element?.user[0]?.payment.forEach((elm) => {
             arr.push(elm);
            })

        });
        getData(arr);
        
        
    }


    const getData = (datas) => {
        console.log("AAAAAAA");
        let arr = datas.map((item , index) => {
            return{
                sl: index+1,
                price:item?.price,
                subscription:item?.subscription
            }
        })
        setSellerData(arr);
    }


    const SelectSubscription = (subscription) => {
        console.log("subbSata", subscription);
        setSelectedSubscription(subscription);
        setSubsListModal(false);

        setConfirmModal(true);
    };

    return (
        <>
            <FilterComponent
                state={customer}
                setState={setCoustomer}
                constState={constState}
            />
            <DataTable
                columns={columns}
                data={sellerData}
                // data={filteredItems}
                pagination

            />

            {model ? (
                <>
                    <div
                        tabIndex={-1}
                        role="menu"
                        aria-hidden="false"
                        className="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-end show"
                        data-popper-placement="bottom-end"
                        style={{
                            position: "absolute",
                            inset: "14px 38px auto auto",
                            transform: "translate(0px, 44px)",
                            display: "flex",
                            flexDirection: "row",
                        }}
                    >
                        <img
                            style={{
                                height: "400px",

                            }}
                            src={imageSrc}
                        />
                        {/* <button onClick={() => Close()}>x</button> */}
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <svg
                                onClick={() => Close()}
                                style={{ color: "red", cursor: 'pointer' }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="28"
                                height="28"
                                fill="currentColor"
                                class="bi bi-x"
                                viewBox="0 0 16 16"
                            >
                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                            </svg>
                        </div>
                    </div>
                </>
            ) : null}

            {subsListModal && (
                <AddSubscription
                    subscription_details={subscription_details}
                    userId={userId}
                    SelectSubscription={SelectSubscription}
                    close={() => setSubsListModal(false)}
                // getData={getData}
                />
            )}

            {confirmModal && (
                <ConfirmSubscription
                    userId={userId}
                    selectedSubscription={selectedSubscription}
                    close={() => setConfirmModal(false)}
                />
            )}
        </>
    );
};

export default UserCommisonDetails;
