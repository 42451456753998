import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./TableCol";
var constState = null;
const Index = () => {
  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`viewServiceHistory`, "GET", {});
    console.log("seCascsar",result);

    if (result && result.status) {
      let arr = result.data.map((it, ind) => {
        return {
          ...it,
          sl: ind + 1,
          action: (
            <button onClick={() => viewOrder(it)} className="btn btn-primary">
              view orders
            </button>
          ),
        };
      });
      constState=arr
      setServiceList(arr); // set data
    } else {
      toast.error(result.message || result.error);
    }
  };
  console.log("serviceList", serviceList);
  const viewOrder = (payload) => {
    const name =payload.userName.split(" ")
    navigate("/service-history/" + name[0], { state: payload });
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>View Service History</h5>
      </div>
      <FilterComponent
        state={serviceList}
        setState={setServiceList}
        constState={constState}
      />
      <DataTable columns={columns} data={serviceList} pagination />
    </>
  );
};

export default Index;
