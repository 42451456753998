import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import HttpClient from "../../../../utils/HttpClient";
import { SaloonColumns } from "./SalonCol";

const SaloonTable = ({ data, getMember, sellerName }) => {
  const navigate = useNavigate();
  const ModData = data?.map((payload, ind) => {
    return {
      ...payload,
      sl: ind + 1,
      date: moment(payload.createdOn).format("DD-MMM-YYYY"),
      view: <button className="btn btn-primary" onClick={(e) => handleRowClicked(payload, e)}>view</button>,
      // action: payload.approveStatus ? (
      //   <button
      //     onClick={(e) => {
      //       e.stopPropagation();
      //       updateStatus(!payload.approveStatus, payload._id, "salonStatus");
      //     }}
      //     className="btn btn-danger"
      //   >
      //     Deactive
      //   </button>
      // ) : (
      //   <button
      //     onClick={(e) => {
      //       e.stopPropagation();
      //       updateStatus(!payload.approveStatus, payload._id, "salonStatus");
      //     }}
      //     className="btn btn-success"
      //   >
      //     approve
      //   </button>
      // ),
    };
  });
  const updateStatus = async (status, id, type) => {
    // console.log("status,id", status, id, type);
    let result = await HttpClient.requestData(`seller/${type}/${id}`, "PUT", {
      approveStatus: status,
    });
    // console.log("result", result);
    if (result && result.status) {
      getMember();
    } else if (result == undefined) {
      toast.error("Something wrong!");
    } else {
      toast.error(result.message);
    }
  };
  const handleRowClicked = (row, e) => {
    e.stopPropagation();
    let { action, ...rest } = row;

    // console.log("row", row);
    navigate(`/${sellerName}/Saloons/${row.name}`, { state: rest });
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>Saloons</h5>
      </div>
      <DataTable
        columns={SaloonColumns}
        data={ModData}
        pagination
        // onRowClicked={(row, e) => handleRowClicked(row, e)}
      />
    </>
  );
};

export default SaloonTable;
