export const columns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Gender",
    selector: (row) => row.gender,
  },
  {
    name: "Service Name",
    selector: (row) => row.serviceName,
  },
  {
    name: "Start Price",
    selector: (row) => "₹" + row.startPrice    ,
  },
  {
    name: "End Price",
    selector: (row) => "₹" + row.endPrice,
  },
  {
    name: "Main Service Name",
    selector: (row) => row.mainServiceName,
  },
];
