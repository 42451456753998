import React from "react";
const reg = /^[0-9]*$/;
const MobileNumber = ({ label, state, setState, placeholder,erorr,req }) => {
  return (
    <div className="form-group">
      <label>{label }{req&&<span style={{color:"red"}}>*</span>}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="icon-phone" />
        </span>
        <input
          className="form-control p_input"
          name="mobile"
          value={state.mobile}
          onChange={(e) => {
            if(reg.test(e.target.value)) {
              setState({ ...state, mobile: e.target.value });
            }
          }}
          type="text"
          maxLength="10"
          required
          placeholder={placeholder}
        />
      </div>
          <span style={{color:"red",fontSize:12}}>{erorr}</span>
    </div>
  );
};

export default MobileNumber;
