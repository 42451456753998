import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import HttpClient from "../../../utils/HttpClient";
import Swal from "sweetalert2";

// import { columns } from "./TableCol";

import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { BiSearchAlt2 } from "react-icons/bi";
import FilterComponent from "../../../Common/SearchData/Index";
var constState = null;
const UserSubscription = () => {
  const [subscribedUser, setSetSubscribedUser] = useState([]);
  const [hide, setHide] = useState(false);
  const [hide1, setHide1] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Name",
      selector: (row) => row.userData?.name,
    },
    {
      name: "Subscription",
      selector: (row) => row.subscriptionData?.name,
    },
    {
      name: "Price",
      selector: (row) => "₹" + row.subscriptionData?.price,
    },
    {
      name: "Expiry Date",
      selector: (row) => row.expDate,
    },
    {
      name: "Subscription Date",
      selector: (row) => row.subsDate,
    },

    //     {
    //   name: "Block",
    //   selector: (row) => row.operation,
    // },
    //         {
    //   name: "Unblock",
    //   selector: (row) => row.unblock,
    // },

    {
      name: "Block / Unblock",
      selector: (row) => row.action,
    },
    {
      name: "Delete",
      selector: (row) => row.delete,
    },
  ];

  const SubscriptionBlock = async (subcriptionId, userId) => {
    let data = {
      subcriptionId: subcriptionId,
      userId: userId,
      cancelStatus:false
    };

    try {
      let res = await HttpClient.requestData(
        "subscription/unblockSubscription",
        "PUT",
        data
      );
      console.log("Cancel Res", res);
      if (res && res?.status) {
        // setHide(true);

        toast.success("Subscription Blocked Successfully");
        getData();
        // setHide1(false);
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SubscriptionUnblock = async (subcriptionId, userId) => {
    let data = {
      subcriptionId: subcriptionId,
      userId: userId,
      cancelStatus: true
    };

    try {
      let res = await HttpClient.requestData(
        "subscription/unblockSubscription",
        "PUT",
        data
      );
      console.log("Unblock Res", res);
      if (res && res?.status) {
        toast.success("Subscription Unblocked Successfully");
        getData();
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DeleteSubUser =  (subcriptionId, userId) => {
    let data = {
      subcriptionId: subcriptionId,
      userId: userId,
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          let res = await HttpClient.requestData(
            "subscription/deleteSubscription",
            "DELETE",
            data
          );
          console.log("Delete Res", res);
          if (res && res?.status) {
            toast.success("Delete Successfully");
            getData();
          } else {
            toast.error(res?.message);
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  function expiryDate(expDate) {
    let newDate = new Date().toISOString();
  
    if (newDate >= expDate) {
      return true;
    } else {
      return false;
    }
  }

  const getData = async () => {
    let result = await HttpClient.requestData(
      `subscription/checkUserSubscription`,
      "GET",
    
    );
    console.log(result, `result`);

    if (result && result.status) {
      console.log("SubScribed user", result.data);
      let arr = result.data.map((item, ind) => {
        return {
          ...item,
          sl: ind + 1,
          subsDate: moment(item.SubscriptionDate).format("LL"),
          expDate: moment(item.expiryDate).format("LL"),
          //           operation:(
          //             <button onClick={() => SubscriptionBlock(item?.subcriptionId , item?.userData?._id
          // )} type="button" class="btn btn-primary">Block</button>
          //           ),
          //           unblock:(
          //             <button  onClick={() => SubscriptionUnblock(item?.subcriptionId , item?.userData?._id
          // )} type="button" class="btn btn-primary">Unblock</button>
          //           ),
          delete: (
            <button
              onClick={() =>
                DeleteSubUser(item?.subcriptionId, item?.userData?._id)
              }
              type="button"
              class="btn btn-danger"
            >
              Delete
            </button>
          ),
          action: (
            <>
              {expiryDate(item?.expiryDate) ? (
                <button class="btn btn-primary">SubExp</button>
              ) : item?.cancelStatus ? (
                <button
                  onClick={() =>
                    SubscriptionBlock(item?.subcriptionId, item?.userData?._id)
                  }
                  type="button"
                  class="btn btn-primary"
                >
                  Block
                </button>
              ) : (
                <button
                  onClick={() =>
                    SubscriptionUnblock(
                      item?.subcriptionId,
                      item?.userData?._id
                    )
                  }
                  type="button"
                  class="btn btn-primary"
                >
                  Unblock
                </button>
              )}
            </>
          ),
        };
      });
      constState = arr;
      setSetSubscribedUser(arr);
    } else {
      toast.error(result.message || result.error);
    }
  };
  return (
    <>
      <div className="card-header pb-0">
        <h5>View Subscribed User</h5>
      </div>
      <FilterComponent
        constState={constState}
        state={subscribedUser}
        setState={setSetSubscribedUser}
      />
      <DataTable columns={columns} data={subscribedUser} pagination />
    </>
  );
};

export default UserSubscription;
