import axios from "axios";
import React, { useState } from "react";
import { geocodeByAddress, geocodeByPlaceId } from "react-places-autocomplete";
import { toast, ToastContainer } from "react-toastify";
import PlacesAutocomplete from "react-places-autocomplete";
import Places from "../../Places";
import { useLoadScript } from "@react-google-maps/api";

const Adress = ({ label, state, setState, placeholder, callMack, req }) => {
  const [search, setSearch] = useState("");
  const [feilds, allFeilds] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyC-ki3ImgxYzo8K2OCH9yDthHWIWV1yfj4",
    libraries: ["places", "drawing"],
  });

  // var myHeaders = new Headers();
  // myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Access-Control-Allow-Origin", "*");

  // var requestOptions = {
  //   headers: myHeaders,
  // };
  // const searchHandle = (value) => {
  //   setState({ ...state, address: value });
  //   fetch(
  //     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${value}&key=AIzaSyC-ki3ImgxYzo8K2OCH9yDthHWIWV1yfj4`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((res) => {
  //       // console.log(res);
  //       // console.log("res", res.getPlacePredictions);
  //       allFeilds(res.predictions);
  //     })
  //     .catch((e) => console.log(e));
  // };

  // const getLat = (item) => {
  //   // console.log(item, item?.structured_formatting?.main_text, "iteeem");
  //   // console.log('first',  geocodeByPlaceId(item.place_id))
  //   //  let rrrr= await  geocodeByPlaceId(item.place_id)
  //   // setState({ ...state, address: item.description});
  //   setState((prev) => {
  //     return {
  //       ...prev,
  //       address: item.description,
  //     };
  //   });
  //   fetch(
  //     `https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=${item?.description}&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=AIzaSyC-ki3ImgxYzo8K2OCH9yDthHWIWV1yfj4`,
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((res) => {
  //       // console.log(res);
  //       // toast.error(res.status)
  //       callMack(
  //         [
  //           res.candidates[0].geometry.location.lat,
  //           res.candidates[0].geometry.location.lng,
  //         ],
  //         "location added"
  //       );
  //       fetch(
  //         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${res.candidates[0].geometry.location.lat},${res.candidates[0].geometry.location.lng}&key=AIzaSyDoTIrlJxHd9rTLoytrigY9piIQk1OcTJk`,
  //         requestOptions
  //       )
  //         .then((res) => res.json())
  //         .then((got) => {
  //           // console.log(got.results[0].address_components);
  //           let postalArr = got.results[0].address_components.find((it) =>
  //             JSON.stringify(it).includes("postal_code")
  //           );
  //           let stateArr = got.results[0].address_components.find((it) =>
  //             JSON.stringify(it).includes("administrative_area_level_1")
  //           );
  //           // console.log('postalArr[0]?.long_name', postalArr)
  //           setState((prev) => {
  //             return {
  //               ...prev,
  //               pinCode: postalArr?.long_name,
  //               state: stateArr?.long_name,
  //             };
  //           });
  //         });
  //       allFeilds([]);
  //     })
  //     .catch((e) => console.log(e));
  // };
  return (
    <div className="form-group">
      <label>
        {label}
        {req && <span style={{ color: "red" }}>*</span>}
      </label>
      {/* <div className="input-group">
        <span className="input-group-text p-0">
          <i className="icon-phone" />
        </span>
        <textarea
          className="form-control p_input"
          name="dis"
          value={state.address}
          onChange={(e) => {
            setState({ ...state, address: e.target.value });
          }}
          type="text"
          required
          placeholder={placeholder}
        />
       
      </div> */}
      <ToastContainer />
      {isLoaded && (
        <Places
          setSearchPlace={(position) => {
            setState((prev) => {
              return {
                ...prev,
                address: position.address,
                pinCode: position.zipcode,
                state: position?.state,
              };
            });
            callMack([position.lat, position.lng], "location added");
            console.log(position);
            // setSearchPlace(position);
            // mapRef.current?.panTo(position);
          }}
        />
      )}
      {/* <div className="custom-input-group">
        <input
          value={state.address}
          onChange={(val) => searchHandle(val.target.value)}
          className="form-control"
          type="search"
          placeholder="Search"
        />
        {feilds.length > 0 && (
          <ul>
            {feilds.map((item) => {
              return <li onClick={() => getLat(item)}>{item?.description}</li>;
            })}
          </ul>
        )}
      </div> */}
    </div>
  );
};

export default Adress;
