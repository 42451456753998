import React, { useState } from "react";
import HttpClient from "../../../../utils/HttpClient";
import { AiFillDelete } from "react-icons/ai";
import { toast } from "react-toastify";
const ImageUpload = ({ label, state, setState, type, value ,req}) => {
  const [prev, setPrev] = useState("");
  const onImageUpload = (e, type) => {
    console.log("type", type);
    let file = e.target.files[0];

    imageUploadApi(file, type);
  };
  const imageUploadApi = async (file, type) => {
    console.log(file, "fle");
    let data = new FormData();
    data.append("image", file);

    let result = await HttpClient.fileUplode("salonImageUpload", "POST", data);
    console.log("result", result);
    if(result.status){
      setPrev(result.url);
      if (type === "gst") {
        setState({ ...state, gstImage: result.url });
      } else if (type === "tradeL") {
        setState({ ...state, tradeLicenseImage: result.url });
      } else if (type === "sallon") {
        setState({ ...state, image: result.url });
      }
    }else{
      toast("Something Went Wrong")
    }

  };
  const deleteImg = (typ) => {
    if (typ === "gst") {
      setState({ ...state, gstImage: "" });
    } else if (typ === "tradeL") {
      setState({ ...state, tradeLicenseImage: "" });
    } else if (typ === "sallon") {
      setState({ ...state, image: "" });
    }
  };
  // console.log("state", state);
  return (
    <>
      <div
        className="position-relative mb-4 d-flex align-items-center justify-content-between"
        style={{ border: "1px solid rgb(206, 212, 218)", padding: "1%" }}
      >
        <label>{label}{req&&<span style={{color:"red"}}>*</span>}</label>
        <label className="form_label d-flex align-items-center justify-content-between mb-2">
          Browse
          <input
            type="file"
            className="form-control"
            id="uploadFile"
            accept="image/*"
            onChange={(e) => onImageUpload(e, type)}
          />
        </label>
      </div>

      {/* <div
        className="position-relative mb-4 d-flex align-items-center justify-content-between"
        style={{ border: "1px solid #ced4da", padding: "1%" }}
      >
        <label>{label}</label>
        <label className="form_label d-flex align-items-center justify-content-between mb-2">
          Browse
          <input
            type="file"
            className="form-control"
            id="uploadFile"
            accept="image/*"
            onChange={(e) => onImageUpload(e, type)}
          />
        </label>
      </div> */}
      {
        <div>
          {type === "gst" && state.gstImage !== "" ? (
            <>
              <img
                className="m-3"
                style={{ height: 70, width: 70 }}
                src={state.gstImage}
              />
              <span
                className="m-3"
                style={{ cursor: "pointer" }}
                onClick={() => deleteImg(type)}
              >
                <AiFillDelete color="red" />
              </span>
            </>
          ) : null}
          {type === "tradeL" && state.tradeLicenseImage !== "" ? (
            <>
              <img
                className="m-3"
                style={{ height: 70, width: 70 }}
                src={state.tradeLicenseImage}
              />
              <span
                className="m-3"
                style={{ cursor: "pointer" }}
                onClick={() => deleteImg(type)}
              >
                <AiFillDelete color="red" />
              </span>
            </>
          ) : null}
          {type === "sallon" && state.image !== "" ? (
            <>
              <img
                className="m-3"
                style={{ height: 70, width: 70 }}
                src={state.image}
              />
              <span
                className="m-3"
                style={{ cursor: "pointer" }}
                onClick={() => deleteImg(type)}
              >
                <AiFillDelete color="red" />
              </span>
            </>
          ) : null}
        </div>
      }
    </>
  );
};

export default ImageUpload;
{
  /* <img
              style={{ height: 70, width: 70 }}
              src={
                type === "gst"
                  ? state.gstImage
                  : type === "tradeL"
                  ? state.tradeLicenseImage
                  : type === "sallon"
                  ? state.image
                  : null
              }
            /> */
}
