import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Adress from "../../../Common/Layout/FormInput/Adress";
import Email from "../../../Common/Layout/FormInput/Email";
import ImageUpload from "./FormComponent/ImageUpload";
import MobileNumber from "../../../Common/Layout/FormInput/MobileNumber";
import Name from "../../../Common/Layout/FormInput/Name";
import Password from "../../../Common/Layout/FormInput/PassWord";
import HttpClient from "../../../utils/HttpClient";
import GstNumber from "./FormComponent/GstNumber";
import PinCode from "./FormComponent/PinCode";
import States from "./FormComponent/States";
import TradeLicesenceNumber from "./FormComponent/TradeLicesenceNumber";
import MapModal from "./MapModal";
const body = {
  name: "",
  email: "",
  mobile: "",
  address: "",
  state: "",
  pinCode: 721153,
  tradeLicense: "",
  password: "",
  tradeLicenseImage: "",
  gstNo: "",
  gstImage: "",
  image: "",
  location: null,
};
const Index = () => {
  const location = useLocation().state;
  // console.log("location", location);
  const [formData, setFormData] = useState(location || body);
  const [gstImgPrev, setGstImagPrev] = useState(location?.gstImage || "");
  const [tradeLImgPrev, setTradeLImgPrev] = useState(
    location?.tradeLicenseImage || ""
  );

  const navigate = useNavigate();
  const [locState, setLocState] = useState("add location");
  const [salonImgPrev, setSalonImgPrev] = useState(location?.image || "");
  console.log(formData, "ffff");
  const submit = async (e) => {
    e.preventDefault();
    console.log('formData', formData)
    // return
    if (
      formData.name === "" ||
      formData.email === "" ||
      formData.mobile === "" ||
      formData.password === "" ||
      formData.address === "" ||
      formData.pinCode === "" ||
      formData.image === "" ||
      // formData.gstImage === "" ||
      // formData.tradeLicense === "" ||
      // formData.tradeLicenseImage === "" ||
      // formData.gstNo === "" ||
      formData.state === "" ||
      formData.location === null
    ) {
      toast.warn("all field are required!!!");
    } else {
      if (formData.mobile.length != 10) {
        // console.log(formData.mobile)
        toast.warn("Enter Valid Number!!!");
        return;
      }
      if (location) {
        let res = await HttpClient.requestData(
          `updateSalonProfile/${location._id}`,
          "PUT",
          formData
        );
        // console.log(res);
        if (res && res.status) {
          toast.success("Saloon updated successfully !!!");
          setTimeout(() => {
            navigate("/view-added-saloon");
          }, 1000);
        } else {
          toast.error(res.error);
        }
      } else {
        let res = await HttpClient.requestData(
          "salonRegister",
          "POST",
          formData
        );
        console.log(res);
        if (res && res.status) {
          toast.success("Saloon Registered !!!");
          setTimeout(() => {
            navigate("/view-added-saloon");
          }, 1000);
        } else {
          toast.error(res.error|| res.message);
        }
      }
    }
  };

  const getCoOrdinate = (data, loc) => {
    setLocState(loc);
    let co = {
      type: "Point",
      coordinates: data,
    };
    // setFormData({ ...formData, location: co });
    setFormData((prev) => {
      return {
        ...prev,
        location: co,
      };
    });
  };
  // console.log("formData", formData);
  return (
    <>
      <div className="card-header pb-0">
        <h5>{location ? "Update" : "Register"} Saloon</h5>
      </div>
      <form className="theme-form login-form" onSubmit={submit}>
        <h4>Register saloon</h4>
        <h6>Add Saloon information from here</h6>
        <Name
        req={true}
          label="Saloon Name"
          state={formData}
          setState={setFormData}
          placeholder="Enter Saloon Name"
        />
        <MobileNumber
        req={true}
          label="Mobile Number"
          state={formData}
          setState={setFormData}
          placeholder="Enter Mobile Number"
        />
        <Email
        req={true}
          label="Email Adress"
          state={formData}
          setState={setFormData}
          placeholder="Enter Saloon Email Adress"
        />
        <Password
        req={true}
          label="Password"
          state={formData}
          setState={setFormData}
          placeholder="Enter PassWord"
        />
        <Adress
        req={true}
          label="Address"
          state={formData}
          setState={setFormData}
          placeholder="Enter Adress"
          callMack={getCoOrdinate}
        />
        {/* <button
          type="button"
          className="btn btn-success my-3 mr-3"
          data-toggle="modal"
          data-target=".bd-example-modal-lg"
        >
          {locState}
          Add location
        </button> */}
        <MapModal callMack={getCoOrdinate} />
        {/* <div className="row mb-3">
          <States
            value={formData.state}
            reactState={formData}
            setReactState={setFormData}
            label="State"
          />
          <PinCode
            label="Pincode"
            state={formData}
            setState={setFormData}
            placeholder="Enter pin"
          />
        </div> */}
        <GstNumber
          label="GST Number"
          state={formData}
          setState={setFormData}
          placeholder="Gst Number"
        />
        <TradeLicesenceNumber
          label="Trade Licence Number"
          state={formData}
          setState={setFormData}
          placeholder="Enter Trade Licence Number"
        />
        <ImageUpload
          state={formData}
          setState={setFormData}
          label="GST File"
          value={formData.gstImage}
          type="gst"
        />{" "}
        <ImageUpload
          state={formData}
          setState={setFormData}
          label="Trade Licesnse File"
          value={formData.tradeLicenseImage}
          type="tradeL"
        />{" "}
        <ImageUpload
        req={true}
          state={formData}
          setState={setFormData}
          label="Upload Saloon Image"
          value={formData.tradeLicenseImage}
          type="sallon"
        />
        <div className="form-group">
          <button className="btn btn-primary btn-block" type="submit">
           {location?"Update Saloon":"Register Saloon"} 
          </button>
        </div>
      </form>
      
    </>
  );
};

export default Index;
