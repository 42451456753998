import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import FilterComponent from "../../../Common/SearchData/Index";
import HttpClient from "../../../utils/HttpClient";
// import { columns } from "./TableCol";
var constState = null;
const Index = () => {
  const [sellsArr, setSellsArr] = useState([]);
  const [pending, setPending] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(`getSalon`, "GET", {});
    console.log("saloonView",result);
    // console.log(result);
    if (result && result.status) {
      let arr = result.data.map((element, ind) => {
        return {
          ...element,
          sl: ind+1,
          edit:<div> <button className="btn btn-primary">edit</button></div>,
          password: element.passwordCrypto,
          datee: moment(element.createdOn).format("DD-MMM-YYYY"),
          deActive: (
            <button
              onClick={(e) =>
                activeDeactiveseller(element._id, element.enableStatus, e)
              }
              className="btn btn-danger"
            >
              Deactive
            </button>
          ),
          active: (
            <button
              onClick={(e) =>
                activeDeactiveseller(element._id, element.enableStatus, e)
              }
              className="btn btn-success"
            >
              Approve
            </button>
          ),
          target: (
            <button
              onClick={(e) => {
                e.stopPropagation();
                navigate(`/add-target/${element.name}`, { state: element });
              }}
              className="btn btn-info"
            >
              View
            </button>
          ),
          viewtarget: (
            <div>
              <button type="button" className="btn m-1 btn-sm btn-info">
                Saloons <span className="badge badge-light">0</span>
              </button>
              <br />
              <button type="button" className="btn m-1 btn-sm btn-success">
                Users <span className="badge badge-light">0</span>
              </button>
            </div>
          ),
        };
      });
      constState = arr;
      setSellsArr(arr);
      setPending(false);
    } else {
      setPending(false);
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  const activeDeactiveseller = async (id, status, e) => {
    e.stopPropagation();
    let result = await HttpClient.requestData(
      `updateSalonEnableStatus/${id}`,
      "PUT",
      {
        enableStatus: !status,
      }
    );

    if (result && result.status) {
      toast.success(result.message);
      getData();
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const columns = [
    {
      name: "Sl No",
      selector: (row) => row.sl,
    },
    {
      name: "Avtar",
      selector: (row) => (
        <img style={{ height: 38, width: 38 }} src={row.image} />
      ),
    },
    {
      name: "Name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
    },
    {
      name: "Date",
      selector: (row) => row.datee,
    },
    {
      name: "Number",
      selector: (row) => row.mobile,
    },
    {
      name: "Password",
      selector: (row) => row.password,
    },
    // {
    //   name: "Target",
    //   selector: (row) => row.target,
    // },
    {
      name: "Action",
      selector: (row) => (
        <div style={{display:"flex",justifyContent:"space-between",flexDirection:"column",margin:"5px"}}>
          <span
          style={{margin:"5px"}}
            onClick={(e) => {
              {
                e.stopPropagation();
                navigate(`/add-saloon/`, {
                  state: {
                    email: row.email,
                    image: row.image,
                    mobile: row.mobile,
                    name: row.name,
                    _id: row._id,
                    state: row.state,
                    pinCode: row.pinCode,
                    address: row.address,
                    password: row.password,
                    tradeLicenseImage: row.tradeLicenseImage,
                    gstNo: row.gstNo,
                    gstImage: row.gstImage,
                    state: row.state,
                    tradeLicense: row.tradeLicense,
                    location: row.location,
                  },
                });
              }
            }}
          >
            {row.edit}{" "}
          </span>
          <div>{row.enableStatus ? row.deActive : row.active}</div>{" "}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Added Saloon</h5>
      </div>
      <FilterComponent
        state={sellsArr}
        setState={setSellsArr}
        constState={constState}
      />
      <DataTable
        progressPending={pending}
        columns={columns}
        data={sellsArr}
        pagination
      />
    </>
  );
};

export default Index;
