import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import HttpClient from "../../utils/HttpClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { reactLocalStorage } from "reactjs-localstorage";
import { AiFillEye } from "react-icons/ai";

const userData = {
  email: "",
  password: "",
};
const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(userData);
  const [eye, setEye] = useState(false);
  const submit = async (e) => {
    e.preventDefault();
    let result = await HttpClient.requestData("login", "POST", formData);
    console.log("result", result);
    if (result.status) {
      reactLocalStorage.set("loginStatus", true);
      reactLocalStorage.setObject("adminData", result.data);
      toast.success(result.message);

      setTimeout(() => {
        navigate("/");
      }, 1500);
    } else {
      toast.error(result.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <section>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-12">
              <div className="login-card">
                <form className="theme-form login-form" onSubmit={submit}>
                  <h4>Login</h4>
                  <h6>Welcome back! Log in to your account.</h6>
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icon-email" />
                      </span>
                      <input
                        className="form-control p_input"
                        name="email"
                        value={formData.email}
                        onChange={(e) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                        type="email"
                        required
                        placeholder="Test@gmail.com"
                      />
                    </div>
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label>Password</label>
                    <div className="input-group">
                      <span className="input-group-text">
                        <i className="icon-lock" />
                      </span>
                      <input
                        className="form-control"
                        type={`${eye ? "text" : "password"}`}
                        name="login[password]"
                        required
                        placeholder="*********"
                        value={formData.password}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            password: e.target.value,
                          });
                        }}
                      />
                      {/* <div className="show-hide"><span className="show"></span></div> */}
                    </div>
                    <div
                      className=""
                      style={{
                        position: "absolute",
                        top: "35px",
                        right: "15px",
                        zIndex: "69",
                      }}
                      onClick={() => setEye(!eye)}
                    >
                      <AiFillEye />
                    </div>
                  </div>
                  <div className="form-group">
                    {/* <div className="checkbox">
                      <input id="checkbox1" type="checkbox" />
                      <label className="text-muted" htmlFor="checkbox1">
                        Remember password
                      </label>
                    </div> */}
                    {/* <a className="link" href="forget-password.html">Forgot password?</a> */}
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary btn-block" type="submit">
                      Sign in
                    </button>
                  </div>
                  {/* <div className="login-social-title">
                    <h5>Sign in with</h5>
                  </div> */}
                  <div className="form-group">
                    {/* <ul className="login-social">
                      <li><a href="https://www.linkedin.com/login" target="_blank"><FiLinkedin /></a></li>
                      <li><a href="https://www.linkedin.com/login" target="_blank"><FiTwitter /></a></li>
                      <li><a href="https://www.linkedin.com/login" target="_blank"><FiFacebook /></a></li>
                      <li><a href="https://www.instagram.com/login" target="_blank"><FiInstagram /></a></li>
                    </ul> */}
                  </div>
                  {/* <p>Don't have account?<a className="ms-2" href="sign-up.html">Create Account</a></p> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
