import React, { useRef, useState } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { toast, ToastContainer } from "react-toastify";
import { configure } from "@testing-library/react";
import Searchable from 'react-searchable-dropdown';
const center = {
  lat: 20.5937,
  lng: 78.9629,
};
const MapModal = ({ callMack }) => {
  const [markerValue, setMarkerValue] = useState(center);
  const modalRef = useRef();
  //Google Map Config
  const containerStyle = {
    width: "861px",
    height: "400px",
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDoTIrlJxHd9rTLoytrigY9piIQk1OcTJk",
  });
  const [map, setMap] = useState(null);
  const getRef = () => {
    modalRef.current.click();
    // toast.success("location added successfully !");
  };

  return (
    <>
      <div
        className="modal fade bd-example-modal-lg"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <ToastContainer />
        <div
          className="modal-dialog"
          role="document"
          style={{ maxWidth: "900px" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                ref={modalRef}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {isLoaded ? (
                <GoogleMap
                  onClick={(ev) => {
                    let marker = { lat: ev.latLng.lat(), lng: ev.latLng.lng() };
                    setMarkerValue(marker);
                    callMack(
                      [ev.latLng.lat(), ev.latLng.lng()],
                      "location added"
                    );
                    getRef();
                  }}
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={4}
                  //   defaultZoom={8}
                  defaultCenter={{ lat: -34.397, lng: 150.644 }}
                >
                  <Marker
                    position={markerValue}
                    // onLoad={onLoad}
                    // onClick={this.onMarkerClick}
                    // name={"Kenyatta International Convention Centre"}
                  />
                </GoogleMap>
              ) : (
                <></>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapModal;
