import React, { useEffect, useRef, useState } from "react";
import Header from "../../Common/Layout/header";
import SideBar from "../../Common/Layout/sideBar";
import Footer from "../../Common/Layout/footer";
import { MDBDataTable } from "mdbreact";
import HttpClient from "../../utils/HttpClient";
import SweetAlert from "react-bootstrap-sweetalert";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Swal from "sweetalert2";

const AddBanner = () => {
  const navigate = useNavigate();
  useEffect(() => {
    fetchBanner()
  }, []);

  const imageRef = useRef()
  const [pincode, setPincode] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [confirm_alert, setconfirm_alert] = useState(false);
  const [delId, setdelid] = useState("");
  const [edit, setEdit] = useState(false);
  const [editId, setEditiD] = useState(false);
  // car state
  const [name, setName] = useState("");
  const [prize, setPrize] = useState("");
  const [perKmPrice, setPerKmPrice] = useState("");
  const [minimumKm, setMinimumKm] = useState("");
  const [image, setImage] = useState("");
  const [progressBar, setProgressbar] = useState(null);

  const Submit = async (e) => {
    e.preventDefault();
    console.log("pincode",pincode);

    if (pincode != "" && image.trim() != "") {
      if (/^(\d{4}|\d{6})$/.test(pincode)) {
        let data = {
          pincode: pincode,
          image: image,
        };
        let result

        if(edit){
            result = await HttpClient.requestData(
                "banner/update/"+editId,
                "PUT",
                data
              );
        }
        else{
            result = await HttpClient.requestData(
                "banner/create",
                "POST",
                data
              );
        }

        
        console.log(result);
        if (result && result.status) {
          toast.success(edit?"Updated Successfully" :"Banner Added Successfully");
          setEdit(false)
          setEditiD("")
          setPincode("")
          imageRef.current.value=""
          setImage("")
          fetchBanner()
        }
      } else {
        toast.warning("Please enter valid pincode");
      }

      // console.log("send", data)
    } else {
      toast.warning("All feilds are required");
    }
  };
  const handleChange1 = async (file) => {
    let data = new FormData();
    data.append("image", file);
    let result = await HttpClient.fileUplode(
      "banner/uploadBannerImage",
      "POST",
      data
    );
    console.log(result);
    if (result && result.status) {
      setImage(result.url);
    }
    // if (e.target.files.length > 0) {
    //     const imageArr = Array.from(e.target.files[0])
    //     setpreview(URL.createObjectURL(e.target.files[0]))
    //     imageUpload(e.target.files[0])
    // }
  };

  const fetchBanner =async ()=>{
    let result  = await HttpClient.requestData("banner/viewAll","GET")
    if(result && result.status){
        let data = result.data.map((item,i)=>{
            return {
                sl:i+1,
                pincode:item.pincode,
                image:<img src={item.image} style={{height:"100px",width:"100px"}}/>,
                action:<div style={{display:"flex"}}>
                <button style={{margin:"5px"}} className="btn btn-primary" onClick={()=>editEnable(item)}>Edit</button>
                <button style={{margin:"5px"}}  className="btn btn-danger" onClick={()=>deleteHandle(item._id)}>Delete</button>

                </div>
            }
        })
        setCategoryData(data)
    }
  }





  const deleteHandle = async(id)=>{

    Swal.fire({
        title: 'Do you want to Delete?',
        // showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: 'Delete',
    })
    .then(async(result)=>{
        if(result.isConfirmed){
            let result  = await HttpClient.requestData(`banner/delBanner/${id}`,"PUT",{})
            console.log(result)
            if(result && result.status){
                toast.success("Deleted Successfully")
                fetchBanner()
            }

        }

    })
  }

  const editEnable = (item)=>{
    
    setEditiD(item._id)
    setImage(item.image)
    setPincode(item.pincode)
    setEdit(true)
    window.scroll(0,0)

  }

  const data = {
    columns: [
      {
        label: "Sl.",
        field: "sl",
        sort: "asc",
        width: 150,
      },
      {
        label: "Pincode",
        field: "pincode",
        sort: "asc",
        width: 270,
      },

      {
        label: "Banner Image",
        field: "image",
        sort: "asc",
        width: 270,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 100,
      },
    ],
    rows: categoryData,
  };

  return (
    <div>
      {/* <ToastContainer /> */}

      <div className="card">
        <div className="card-header pb-0">
          <h5> Add Banner</h5>
        </div>
        <form className="form theme-form">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label"> Pincode *</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      value={pincode}
                      type="text"
                      onChange={(val) => setPincode(val.target.value)}
                    />
                  </div>
                </div>

                <h5> Banner image</h5>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">
                    Upload Image
                  </label>
                  <div className="col-sm-9">
                    <input
                    // value={image}
                    ref={imageRef}
                      className="form-control"
                      type="file"
                      onChange={(val) => {
                        handleChange1(val.target.files[0]);
                      }}
                    />
                  </div>
                </div>
                {image != "" ? (
                  <img src={image} height="100px" widtth="100px" />
                ) : null}

                {progressBar > 100 && (
                  <div style={{ width: 100, height: 100 }}>
                    <CircularProgressbar
                      value={progressBar}
                      text={`${progressBar}%`}
                    />
                  </div>
                )}
                {/*  <div className="mb-3 row">
                                                <label className="col-sm-3 col-form-label">Categories Description in English</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" placeholder="" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-3 col-form-label">Categories Description in Hindi</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" placeholder="" />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label className="col-sm-3 col-form-label">Categories Description in Gujrati</label>
                                                <div className="col-sm-9">
                                                    <input className="form-control" type="text" placeholder="" />
                                                </div>
                                            </div>
 */}
              </div>
            </div>
          </div>
          <div className="card-footer text-end">
            <div className="col-sm-9 offset-sm-3">
              <button
                className="btn btn-primary"
                type="submit"
                onClick={Submit}
              >
                {edit ? " Save Edit" : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="card-body">
        <MDBDataTable responsive bordered data={data} />
      </div>
    </div>
  );
};

export default AddBanner;
