import React from "react";
const reg = /^[0-9]*$/;
const Discount = ({ label, state, setState, placeholder }) => {
  return (
    <div className="form-group">
      <label>{label}</label>
      <div className="input-group">
        <span className="input-group-text">
          <i className="icon-phone" />
        </span>
        <input
          className="form-control p_input"
          name="dis"
          value={state.additionalDays}
          onChange={(e) => {
            // if (reg.test(e.target.value)) {
              setState({ ...state, additionalDays: e.target.value });
            // }
          }}
          type="number"
          required
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default Discount;
