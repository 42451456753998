import React from 'react'
import Index from './route';
// import 'bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import { reactLocalStorage } from 'reactjs-localstorage';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import "./App.css"


function App() {
  
  return (
    <div className="App">
      <Index />
    </div>
  );
}

export default App;
