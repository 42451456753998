import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { Await, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../../Common/Layout/header";
import SideBar from "../../../Common/Layout/sideBar";
import Footer from "../../../Common/Layout/footer";
import HttpClient from "../../../utils/HttpClient";
import { columns } from "./DataCol";
import Swal from "sweetalert2";
import FilterComponent from "../../../Common/SearchData/Index";
var constState = null;
const Index = () => {
  const navigate = useNavigate();
  const [subList, setSubsList] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    let result = await HttpClient.requestData(
      `subscription/viewAll`,
      "GET",
      {}
    );

    if (result && result.status) {
      console.log(result, `result`);
      let arr = result.data.map((it, ind) => {
        return {
          ...it,
          sl: ind + 1,
          action: (
            <>
              <button onClick={() => updateSub(it)} className="btn btn-primary">
              update
              </button>


            </> 
          ),
          delete:(
            <button onClick={() => deleteSub(it?._id)} class="btn btn-danger">
            Delete
          </button>
          ),

          priority:it.priority,


          status: <button onClick={()=>StatusChange(it._id,it.activatedStatus)} className={`btn btn-${it.activatedStatus?"success":"danger"}`}>{it.activatedStatus ? "Active" : "Inactive"}</button>,
        };
      });
      constState = arr;
      setSubsList(arr); // set data
    } else {
      toast.error(result.message);
    }
  };

  const deleteSub = async(id) => {

    let endpoint = `subscription/update/${id}`

    let data = {
      "isDeleted":true
    }

     Swal.fire ({
      title: 'Are you sure?',
      text: "You won't to delete this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async(result) => {
      if (result.isConfirmed) {
     
          let response = await HttpClient.requestData(endpoint , "PUT" , data);
          console.log("RESDELETE", response);
          if(response && response.status){
            toast.success("Delete Successfully");
            getData();
     
      }
    }
  })

  }
  const updateSub = (data) => {
    navigate("/add-subscription", { state: data });
  };

  const StatusChange = async(id,status)=>{
    let result= await HttpClient.requestData(`subscription/update/${id}`,"PUT",{activatedStatus:!status})

    if(result && result.status){
      toast.success("Updated Successfully")
      getData()
    }
    else{
      toast.error("Please try again")
    }
  }

  return (
    <>
      <div className="card-header pb-0">
        <h5>View Subscription</h5>
      </div>
      <FilterComponent
        state={subList}
        setState={setSubsList}
        constState={constState}
      />
      <DataTable columns={columns} data={subList} pagination />
    </>
  );
};

export default Index;
