import moment from "moment";

export const SaloonColumns = [
  {
    name: "Sl No",
    selector: (row) => row.sl,
  },
  {
    name: "Image",
    selector: (row) => (
      <img style={{ height: 38, width: 38 }} src={row.image} />
    ),
  },
  {
    name: "Name",
    selector: (row) => row.name,
  },
  {
    name: "Email",
    selector: (row) => row.email,
  },
  {
    name: "Date",
    selector: (row) => row.date,
  }, {
    name: "Details",
    selector: (row) => row.view,
  },
  // {
  //   name: "Action",
  //   selector: (row) => row.action,
  // },
  
];
